import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import HomeUpdateCard from '../../Components/HomeSectionCard/HomeSectionCard';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import { onError } from '../../libs/errorLib';
import NewsService from '../../services/news.service';
import "./HomePage.css";

const HomeNews = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        getNews()
    }, []);
    const getNews = async () => {
        setIsLoading(true);
        try {
            const response = await NewsService.getList()
            const sortedData = response?.results?.sort((a, b) => {
                let A = new Date(a.created)
                let B = new Date(b.created)
                return A - B;
            })
            setDataSource(sortedData);
        } catch (e) {
            onError(e)
        } finally {
            setIsLoading(false)
        }

    }
  return (
    <div  className="home_updates_main">
        <h2 className='theme-h2'>Latest News</h2>
        {isLoading?<LoadingSpinner/>:
            <>
                {dataSource.length?
                    <>
                        <div className="home-updates">
                            {
                                dataSource?.map((item, index)=>index<3 && 
                                <HomeUpdateCard 
                                    imageID={item.bannerImageId}
                                    created={item.created}
                                    title={item.title}
                                    status={item.status}
                                    content={item.content}
                                    id={item.id}
                                    type="news"
                                />)
                            }              
                        </div>
                        <div className='home_view_button'>
                            <Link to="/news" className='primary_btn'>View All News</Link>
                        </div>
                    </> :
                    <div style={{textAlign:"center"}}>No Latest News</div>
                }
            </>
        }
    </div>
  )
}

export default HomeNews