import React, { useEffect, useState } from "react";
import { DownOutlined, RightCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import { Link, useNavigate } from "react-router-dom";
import JobOpeningService from "../../services/jobOpening.service";
import { onError } from "../../libs/errorLib";



const PastVacancies = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [selectedId, setSelectedId] = useState();

    const handleClick = (id) => {
        setSelectedId(id !== selectedId ? id : null);
    };

    useEffect(() => {
        getJobs()
    }, []);
    const getJobs = async () => {
        setIsLoading(true);
        try {
            const response = await JobOpeningService.jobs()
            // console.log("past job vacancies",response)
            setDataSource(response.results);
        } catch (e) {
            onError(e)
        } finally {
            setIsLoading(false)
        }
    }

    const filteredData = dataSource?.filter((job) => job.status == "close");

    return (
        <>
            <div className="listing-main">
                {!isLoading ? (
                    <div className="list-main-card">
                        <h3 className="ribbon">Past Vacancies</h3>
                        <div className="listing-list">
                            <ol>
                                {
                                    filteredData.length === 0 ?
                                        <div style={{ textAlign: "center" }}>
                                            No Past Vacancies
                                        </div> :
                                        filteredData?.map((job) => {
                                            return (
                                                <>
                                                    <li>
                                                        <div className="listing-list-item news-listing">
                                                            {/* Vacancies- */}
                                                            {job?.jobPosition?.title}
                                                            <DownOutlined
                                                                className="primary_btn"
                                                                onClick={() => handleClick(job.id)}
                                                            />
                                                        </div>
                                                        {selectedId === job.id && (
                                                            <div className="select-option">
                                                                <button
                                                                    disabled
                                                                    className="primary_btn"
                                                                // onClick={(e) => openAuthModal(e, job.id)}
                                                                >
                                                                    Position Closed
                                                                </button>
                                                                <ul id="" className="">
                                                                    {/* <b>{job.description}</b> */}
                                                                    <div className="collective_headings">
                                                                        <p>
                                                                            No Of Positions {job.numberOfPositions}
                                                                        </p>
                                                                        <p>
                                                                            {/* Application Deadline 5 Jan 2023 */}
                                                                            Application Deadline{" "}
                                                                            {moment(job.applicationDeadline).format(
                                                                                "DD-MM-YYYY"
                                                                            )}
                                                                        </p>
                                                                        <p>
                                                                            Job Group '{job.jobPosition.jobGroupName}'
                                                                        </p>
                                                                        <p className="secondary_btn" disabled={true}>
                                                                            Contract Type '
                                                                            {job.jobPosition?.contractTypeName}'
                                                                        </p>
                                                                        <p>
                                                                            Contract Type
                                                                            '{job.jobPosition?.contractTypeName}'
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        <div className="job_details">
                                                                            <RightCircleOutlined />
                                                                            <h5>
                                                                                JOB GROUP "{job.jobPosition.jobGroupName}"
                                                                            </h5>
                                                                        </div>
                                                                        <div className="job_details">
                                                                            <RightCircleOutlined />
                                                                            <h5>No Of Positions {job.numberOfPositions}</h5>
                                                                        </div>
                                                                        <div className="job_details">
                                                                            <RightCircleOutlined />
                                                                            <h5>Salary Range: KES (31270-41260)</h5>
                                                                        </div>
                                                                        <div className="job_details">
                                                                            <RightCircleOutlined />
                                                                            <h5>House Allowance: KES 10000</h5>
                                                                        </div>
                                                                        <div className="job_details">
                                                                            <RightCircleOutlined />
                                                                            <h5>Commuter Allowance: KES 4000</h5>
                                                                        </div>
                                                                        <div className="job_details">
                                                                            <RightCircleOutlined />
                                                                            <h5>Location: {job.location}</h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="job_requirement_detail">
                                                                        <h4>Job Requirement</h4>
                                                                        <ul>
                                                                            <li> Possess a Diploma in Law</li>
                                                                            <li>
                                                                                {" "}
                                                                                Must possess leadership skill, ability to
                                                                                work
                                                                                under pressure, organization skills, problem
                                                                                solving and supervisory skills
                                                                            </li>
                                                                            <li>
                                                                                {" "}
                                                                                Demonstrate professional competence in work
                                                                                performance and results.
                                                                            </li>
                                                                        </ul>
                                                                    </div>

                                                                    <div className="job_requirement_detail">
                                                                        <h4>Job Description</h4>
                                                                        <ul>
                                                                            <li> Filling documents in court registries.</li>
                                                                            <li>
                                                                                {" "}
                                                                                Assist in obtaining instructions and
                                                                                witnesses
                                                                                from relevant departments/Sectors.
                                                                            </li>
                                                                            <li>
                                                                                {" "}
                                                                                Assist in verification of supporting legal
                                                                                documents.
                                                                            </li>
                                                                            <li>
                                                                                {" "}
                                                                                Perform any other duties that may be
                                                                                assigned
                                                                                by the supervisor(s).
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </ul>
                                                                <button
                                                                    disabled
                                                                    className="primary_btn"
                                                                // onClick={(e) => openAuthModal(e, job.id)}
                                                                >
                                                                    Position Closed
                                                                </button>
                                                            </div>
                                                        )}
                                                    </li>

                                                </>
                                            )
                                        })
                                }
                            </ol>
                        </div>

                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                            <Link to="/vacancies" className="primary_btn">go to New Vacancies</Link>
                        </div>
                    </div>
                ) : (
                    <LoadingSpinner />
                )}
            </div>
        </>
    );
};

export default PastVacancies;
