import React from "react";
import axios from "axios";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./Store/store";
import "./index.css";
import App from "./App";
import config from "./config";

axios.defaults.baseURL = config.API_BASE_URL;

const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);

root.render(
    <BrowserRouter>
        <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <App/>
                </PersistGate>
        </Provider>
    </BrowserRouter>
);
