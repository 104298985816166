import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, Select, DatePicker} from 'antd';
import FormOptions from './JobFormOptions';
import dayjs from "dayjs";

const AddDegreeModal = (props) => {
    const [form] = Form.useForm()
    const [id, setId] = useState(null)
    useEffect(() => {
        if (props.qualification) {
            setId(props.qualification.id)
            var qualification = JSON.parse(JSON.stringify(props.qualification))
            qualification.yearOfCompletion = dayjs().year(props.qualification.yearOfCompletion)
            qualification.yearOfStart = dayjs().year(props.qualification.yearOfStart)
            form.setFieldsValue(qualification)
        }else{
            setId(null)
        }
    }, [props.qualification])

    
    const onSubmit = (values) => {
        values.yearOfCompletion = dayjs(values.yearOfCompletion).year()
        values.yearOfStart = dayjs(values.yearOfStart).year()
        props.addQualification(values,id)
        form.setFieldsValue({})
    }
    return (
      <Modal
        className="modal"
        open={props.open}
        title={
          <div className="modal-head">
            Post Secondary School Qualification eg Diploma, Bachelors, Masters.
          </div>
        }
        // onOk={handleOk}
        onCancel={props.handleCancel}
        footer={false}
        // footer={
        //     <div className='modal-footer'>
        //         <button type="button" className='secondary_btn' key="back" onClick={props.handleCancel}>
        //             Close
        //         </button>
        //     </div>
        // }
      >
        <Form form={form} onFinish={onSubmit}>
          <div className="merge-fields">
            <Form.Item
              className="input-50"
              label="Status"
              name="status"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                placeholder="Status"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={FormOptions[11].degreeStatusOptions}
              />
            </Form.Item>
            <Form.Item
              className="input-50"
              label="Course Name"
              name="courseName"
              rules={[{ required: true }]}
            >
              <Input type="text" placeholder="Course Name" />
            </Form.Item>
          </div>

          <Form.Item
            label="Institution"
            name="institution"
            rules={[{ required: true }]}
          >
            <Input type="text" placeholder="Institution" />
          </Form.Item>

          <div className="merge-fields">
            <Form.Item
              className="input-50"
              label="Year of Start"
              name="yearOfStart"
              rules={[{ required: true }]}
            >
              <DatePicker picker="year" placeholder="Year of Start" />
            </Form.Item>
            <Form.Item
              className="input-50"
              label="Year of Completion"
              name="yearOfCompletion"
              rules={[{ required: true }]}
            >
              <DatePicker picker="year" placeholder="Year of Completion" />
            </Form.Item>
          </div>
          <button type="submit" className="primary_btn">
            Save
          </button>
        </Form>
      </Modal>
    );
}

export default AddDegreeModal