
import viceChairperson from "../../assets/images/board_Members/vice_chairperson.png";
import mrJackBOwino from "../../assets/images/board_Members/mr_jack_b_owino.jpg";
import mrRobertWamaya from "../../assets/images/board_Members/mr_robert_wamaya.png";
import aisha from "../../assets/images/board_Members/aisha.png";
import nwan from "../../assets/images/board_Members/nwan.jpg";
import thoma from "../../assets/images/board_Members/chair_person.jpg";
import osman from "../../assets/images/board_Members/Osman.png";

export const BoardMembersData = [
  {
    id: 1,
    role: "Chairperson",
    name: "Hon. Thomas Mweu Kasoa",
    paragraphs: [
      "Thomas Mweu Kasoa is a servant leader and experienced public servant with more than fifteen years in public service.  A member of the Chattered Management Institute London, Thomas has wide knowledge, training and experience in: Leadership, human resource development, governance, legislation, management, policy enactment and analysis among others. He has served as board member in the Machakos county assembly service board, Chairperson Machakos water and sewerage company, Vice-chairperson of the defunct Machakos County Council and currently as a board chairperson of the Nairobi City County Public Service board.  He was a pioneer board member of the Machakos County assembly service board where he was instrumental in guiding the service board in transitioning employees from the municipal administration to the County government dispensation. ",
      "Since assuming office as a board chair of the Nairobi city county public service board, Thomas has utilized his skills, experience and foresight leading a harmonious board and advancing the welfare of employees within the county public service. Thomas read governance and leadership at KCA University and Scott Christian University respectively before graduating with honors from Bournemouth University in the United Kingdom with a Bachelor of Arts degree in Global Business Management. He is also holder of a postgraduate diploma in Professional human resource management and leadership practice from the Chattered Management Institute London.",
    ],
    image: thoma,
  },
  {
    id: 4,
    role: "Vice Chairperson",
    name: "Aisha Karanja",
    paragraphs: [
      `Accomplished, versatile and results driven Professional with over fifteen (15) years of progressive Management experience, high-performing operations with expertise in Building/optimizing organizational processes, and infrastructure to maximize business results. `,
      `Aisha has extensive experience leading organizational transformations where she integrated talent functions to deliver faster end-to-end solutions in the areas of skills,careers,succession,performance management, attraction and retention in organizations. She is a skilled strategist able to transform strategic plans into workable solutions and benchmark performance against key operational targets/goals.`,
      "Highly effective at incorporating creative leadership skills to achieve business objectives.",
      "She has served in different Human Resource leadership capacities and has several years of experience in the world of human resources as a respected and dedicated professional and expert with experience providing employee development, team effectiveness and leadership development, interpretation and implementation of employment/labour laws, policies consulting to all levels, both internally and externally. ",
      "She holds Master’s degree in Community Development and a Bachelor of Arts degree in Political science and sociology , a Post graduate diploma in journalism and mass communication , a Post graduate diploma in Human Resources Management  and  is on-going CHRP finalist. She serves as the Vice-Chairperson of the Nairobi County Public Service Board and a Chairperson of the Human Resource Committee of the Board.",
    ],
    image: aisha,
  },
  {
    id: 1,
    role: "Board Member",
    name: "Dr. Consolata Kimuya",
    paragraphs: [
      `Dr. Consolata Kimuya (Mrs.) holds a Doctorate in Educational Administration from the Maasai Mara
      University amongst many other certifications. She has attended high level training for management
      in Japan, Israel, Finland and Australia among others. She has served in other capacities such as
      Center Organizer for KNEC, S.M.A.S.S.E Center Coordinator, K.S.S.H.A Nairobi Chairperson for
      Capacity Building, Makadara Sub-County Constituency Bursary Fund Committee Member as well as a
      Member of the District Education Board for Makadara Sub-County.`,
      `Dr. Kimuya joined the Nairobi County Public Service Board in 2019 and served as the Vice-chairperson
      of the Board. She is the Chairperson of the Discipline Committee and a member of the Human Resource
      Planning and Management Committee.`,
    ],
    image: viceChairperson,
  },
  {
    id: 2,
    role: "Board Member",
    name: "Mr. Jack B. Owino",
    paragraphs: [
      `Mr. Jack B. Owino hold a Bachelor’s degree in Management and Leadership from the Management 
            University of Africa. He is a seasoned politician.`,
      `Mr. Owino joined the Nairobi County Public Service Board in 2020 and Chairs the Finance & Budgeting 
            Committee. He is also a member of the Human Resource Planning and Management & Governance Committees. `,
    ],
    image: mrJackBOwino,
  },
  {
    id: 3,
    role: "Board Member",
    name: "Mr. Robert Wamaya",
    paragraphs: [
      `Mr Robert Wamaya holds of a Bachelor of Arts degree in Economics and Geography from the University 
            of Nairobi, comes with a great experience in international corporate practice having worked for 20 
            years in the research and strategic planning at Steadman Group of Companies, Synovate and Ipsos.  His 
            strengths include Recruitment, Training, and motivation of field personnel to achieve stated objectives.`,

      `Mr. Wamaya Joined the County Public Service Board in June 2022 and Chairs the Governance Committee. He 
            is also a member of the Discipline Committee and ICT & Intergovernmental Committee. `,
    ],
    image: mrRobertWamaya,
  },
  // {
  //   id: 4,
  //   role: "Board Member",
  //   name: "Aisha Karanja",
  //   paragraphs: [
  //     `Accomplished, versatile and results driven Professional with over fifteen  (15) years of progressive Management experience, high-performing operations with expertise in Building/optimizing organizational processes, and infrastructure to maximize business results.`,
  //     `Aisha has extensive experience leading organizational transformations where she integrated talent functions to deliver faster end-to-end solutions in the areas of skills,careers,succession,performance management, attraction and retention in organizations. She is a skilled strategist able to transform strategic plans into workable solutions and benchmark performance against key operational targets/goals.
  //         Highly effective at incorporating creative leadership skills to achieve business objectives.`,
  //     `She has served in different Human Resource leadership capacities and has several years of experience in the world of human resources as a respected and dedicated professional and expert with experience providing employee development, team effectiveness and leadership development, interpretation and implementation of employment/labour laws, policies consulting to all levels, both internally and externally.`,
  //     `She holds Master’s degree in Community Development and a Bachelor of Arts degree in Political science and sociology , a Post graduate diploma in journalism and mass communication , a Post graduate diploma in Human Resources Management  and  is on-going CHRP finalist.`,
  //   ],
  //   image: aisha,
  // },
  {
    id: 5,
    role: "Board Member",
    name: "Mr.Nyamwaya Nyagara",
    paragraphs: [
      `Mr. Nyamwaya Nyagara is a lawyer by profession and an Advocate of the High Court in Kenya. He was admitted to the Bar in 2004 and has had uninterrupted experience in law practice for 19 years. He has served in the County Public Service for over 16 years in various sections within the office of The County Attorney, including litigation and county prosecution.`,
      `In 2020, Mr. Nyagara Nyamwaya joined the County Public Service Board as the Acting CEO/Secretary of the Board. Additionally, he holds the position of head of the County Public Service Board Secretariat.`,
    ],
    image: nwan,
  },
  {
    id: 6,
    role: "Board Member",
    name: "MR. OSMAN G. YUSUF",
    paragraphs: [
      `A patriotic for public servant with over 30 years working experience in the Government of Kenya, having worked with the Ministry of Water Development and the World Bank. Main area of specialization is Livelihood development in rural Communities adopting participatory development methodologies.`,
      `Mr. Osman Joined the Nairobi  County Public Service Board in June 2022 and chairs the ICT and Inter-Governmental Relations Committee. He is also a member of the Finance & Budgeting Committee and Governance Committee. `,
    ],
    image: osman,
  },
];