import React from 'react';
import { BoardMembersData } from './BoardMembersData';
import "./About.css";


const BoardMembers = () => {
  return (
    <div className="about-main-page">
        <h1>Board's Members</h1>


        {BoardMembersData.length<0?
          <div className='about-main'>
            <p className='about-content'> No data available</p>
          </div>
          :
          
          BoardMembersData.map((member)=>{
            return(
              <div className='about-main' key={member.id}>
                <h2>{member.role}</h2>
                <h3 className="ribbon">{member.name}</h3>
                  <div className='member_profiles'>
                    <div className='about_member_pic'>
                      {member.image && <img src={member?.image} alt={member.name + "'s images"}/>}
                    </div>
                    <div className='member_text_details'>
                      {
                        member.paragraphs.map(paragraph=>
                          <p className='about-content'>
                          {paragraph}
                        </p>
                          )
                      }
                    </div>
                  </div>
              </div>
            )
          })
        }

        {/* <div className='about-main'>
          <h2>Secretariat</h2>
            <p className='about-content'>
              The Nairobi County Public Service board is supported by 17 members of secretariat. The highly qualified 
              officers offer day to day administrative support to the board for optimal performance. 
            </p>
        </div> */}
    </div>
  )
}

export default BoardMembers