import React from "react";
import { Link } from "react-router-dom";
import './ErrorPage.css';

const ErrorPage = () => {
  return (
    <>
      <div className="notfound">
        <h1>Error! 404</h1>
        <h2>WE ARE SORRY, PAGE NOT FOUND!</h2>
        <p>
          THE PAGE YOU ARE LOOKING FOR MIGHT HAVE BEEN REMOVED HAD ITS NAME
          CHANGED OR IS TEMPORARILY UNAVAILABLE.
        </p>
        <Link to="/">back to homepage</Link>
      </div>
    </>
  );
};

export default ErrorPage;