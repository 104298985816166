import axios from '../libs/axios';

class ForgotPasswordService {
    constructor() {
        this.contentType='application/password.forgot.v1+json'
    }
    getCode(payload){
        return axios.post(`/users/profile/forgotPassword`, payload, {
            headers: {
                'Content-Type':this.contentType
            }
        });
    }
    updatePassword(payload){
        return axios.put(`/users/profile/password/update`, payload, {
            headers: {
                'Content-Type':'application/password.update.v1+json'
            }
        });
    }
}

export default new ForgotPasswordService();