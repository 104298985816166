import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HomeUpdateCard from '../../Components/HomeSectionCard/HomeSectionCard';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import { onError } from '../../libs/errorLib';
import NoticesService from '../../services/notices.service';
import "./HomePage.css";


const HomeNotices = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        getShortlists()
    }, []);
    const getShortlists = async () => {
        setIsLoading(true);
        try {
            const response = await NoticesService.newList()
            // console.log("home notice list ",response)
            const sortedData = response?.results?.sort((a, b) => {
                let A = new Date(a.created)
                let B = new Date(b.created)
                return B - A;
            })
            setDataSource(sortedData);
        } catch (e) {
            onError(e)
        } finally {
            setIsLoading(false)
        }

    }
    return (
        <div className="home_updates_main">
            <h2 className='theme-h2'>Notices</h2>
            {isLoading ? <LoadingSpinner /> :
                <>
                    {dataSource.length ?
                        <>
                            <div className="home-updates">
                                {
                                    dataSource?.map((item, index) => index < 3 &&
                                        <HomeUpdateCard
                                            imageID={item.bannerImageId}
                                            created={item.created}
                                            title={item.title}
                                            status={item.status}
                                            content={item.content}
                                            id={item.id}
                                            type="notices"
                                        />)
                                }
                            </div>
                            <div className='home_view_button'>
                                <Link to="/notices" className='primary_btn'>View All Notices</Link>
                            </div>
                        </> :
                        <div style={{ textAlign: "center" }}>No Latest Notices</div>
                    }
                </>
            }
        </div>
    )
}

export default HomeNotices