import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined, RightCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import AuthModal from "../../Components/Navbar/AuthModal";
import { Link, useNavigate } from "react-router-dom";
import JobOpeningService from "../../services/jobOpening.service";
import { onError } from "../../libs/errorLib";



const Vacancies = () => {
  const userProfileNavigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const authUser = useSelector(({ auth }) => auth);

  const handleClick = (id) => {
    setSelectedId(id !== selectedId ? id : null);
  };
  const openAuthModal = (e, id) => {
    if (!authUser.isLoggedIn) {
      setAuthModalOpen(true);
    } else {
      userProfileNavigate("/job-profile/" + id);
    }
  };
  const closeAuthModal = () => {
    setAuthModalOpen(false);
  };

  useEffect(() => {
    getJobs()
  }, []);
  const getJobs = async () => {
    setIsLoading(true);
    try {
      const response = await JobOpeningService.jobs()
      console.log("vaccancies", response)
      setDataSource(response.results);
    } catch (e) {
      onError(e)
    } finally {
      setIsLoading(false)
    }

  }

  return (
    <>
      <div className="listing-main">
        {!isLoading ? (
          <div className="list-main-card">
            <h3 className="ribbon">Vacancies</h3>
            <div className="listing-note">
              <h3>Important Information to Prospective Applicants</h3>
              <ul>
                <li>Applications can <b>ONLY</b> be submitted <b>ONLINE</b> on or before the stipulated deadline.</li>
                <li>Applicants should be <b>Kenyan Citizens</b>.</li>
                <li>Only shortlisted candidates will be contacted.</li>
                <li>Applicant must have no prior Criminal conviction or pending criminal action.</li>
                <li>Nairobi City County is an equal opportunity employer. Qualified women and people
                  living with disabilities are encouraged to apply.
                </li>
              </ul>
            </div>
            <div className="listing-list">
              <ol>
                {
                  dataSource ?
                    dataSource?.map((job) => {
                      return (
                        <>
                          {job?.status !== "close" ? (
                            <li>
                              <div className="listing-list-item news-listing">
                                {/* Vacancies- */}
                                { job?.title }
                                <DownOutlined
                                  className="primary_btn"
                                  onClick={() =>
                                    handleClick(job?.id)
                                  }
                                />
                              </div>
                              {selectedId === job?.id && (
                                <div className="select-option">
                                  <button
                                    className="primary_btn"
                                    onClick={(e) =>
                                      openAuthModal(
                                        e,
                                        job?.id
                                      )
                                    }
                                  >
                                    Apply
                                  </button>
                                  <ul id="" className="">
                                    {/* <b>{job?.description}</b> */}
                                    <div className="collective_headings">
                                      <p>
                                        No Of Positions{" "}
                                        {
                                          job?.numberOfPositions
                                        }
                                      </p>
                                      <p>
                                        {/* Application Deadline 5 Jan 2023 */}
                                        Application
                                        Deadline{" "}
                                        {moment(
                                          job?.applicationDeadline
                                        ).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </p>
                                      <p>
                                        Job Group '
                                        {
                                          job?.jobGroupName
                                            
                                        }
                                        '
                                      </p>
                                      <p
                                        className="secondary_btn"
                                        disabled={true}
                                      >
                                        Contract Type '
                                        {
                                          job?.contractTypeName
                                            
                                        }
                                        '
                                      </p>
                                    </div>
                                    <div>
                                      <div className="job_details">
                                        <RightCircleOutlined />
                                        <h5>
                                          JOB GROUP "
                                          {
                                            job?.jobGroupName
                                              
                                          }
                                          "
                                        </h5>
                                      </div>
                                      <div className="job_details">
                                        <RightCircleOutlined />
                                        <h5>
                                          No Of Positions{" "}
                                          {
                                            job?.numberOfPositions
                                          }
                                        </h5>
                                      </div>
                                      <div className="job_details">
                                        <RightCircleOutlined />
                                        <h5>
                                          Salary Range:
                                          KES (
                                          {
                                            job?.minSalary
                                          }
                                          -
                                          {
                                            job?.maxSalary
                                          }
                                          )
                                        </h5>
                                      </div>
                                      <div className="job_details">
                                        <RightCircleOutlined />
                                        <h5>
                                          House Allowance:
                                          KES{" "}
                                          {
                                            job?.houseAllowance
                                          }
                                        </h5>
                                      </div>
                                      <div className="job_details">
                                        <RightCircleOutlined />
                                        <h5>
                                          Commuter
                                          Allowance: KES
                                          {
                                            job?.commuterAllowance
                                          }
                                        </h5>
                                      </div>
                                      <div className="job_details">
                                        <RightCircleOutlined />
                                        <h5>
                                          Location:{" "}
                                          {job?.location}
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="job_requirement_detail">
                                      <h4>
                                        Job Requirement
                                      </h4>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            job?.qualification,
                                        }}
                                      />
                                    </div>

                                    <div className="job_requirement_detail">
                                      <h4>
                                        Job Description
                                      </h4>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            job?.description,
                                        }}
                                      />
                                    </div>
                                  </ul>
                                  <button
                                    className="primary_btn"
                                    onClick={(e) =>
                                      openAuthModal(
                                        e,
                                        job?.id
                                      )
                                    }
                                  >
                                    Apply
                                  </button>
                                </div>
                              )}
                            </li>
                          ) : (
                            "No Vacancies"
                          )}
                        </>
                      );
                    })
                    : <p>No vacancies</p>
                }
              </ol>
            </div>

            {/* <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Link to="/vacancies/past-vacancies" className="primary_btn">Past Vacancies</Link>
            </div> */}
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </div>
      <AuthModal handleCancel={closeAuthModal} open={authModalOpen} />
    </>
  );
};

export default Vacancies;
