import axios from '../libs/axios';

class NoticesService {
    
    getList(){
        return axios.get(`/notices`, {});
    }
    newList(){
        return axios.get(`/notices`, {});
    }
    getSingle(id){
        return axios.get(`/notices/${id}`);
    }
}

export default new NoticesService();