import React, {useState} from "react";
import {Modal, Form, Input, Button} from "antd";
import {onError} from "../../libs/errorLib";
import ResetPasswordService from "../../services/changePassword.service"
import {onSucess} from "../../libs/onSuccess";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
// import {removeLoginUser} from "../../Store/Auth/authSlice";
// import {useDispatch} from "react-redux";
// import {deleteCookie} from "../../libs/cookie";
// import {onInfo} from "../../libs/onInfo";
// import { useNavigate} from "react-router-dom";

const ResetPassword = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    // const dispatch = useDispatch()
    // const navigate= useNavigate();
    const onSubmit = async (values) => {
        setLoading(true)
        try {
            const passwords={
                "oldPassword": values.oldPassword,
                "newPassword": values.newPassword,
            }
            const response = await ResetPasswordService.update(values)
            onSucess("Update successful.")
            // dispatch(removeLoginUser())
            // deleteCookie("access_token")
            // deleteCookie("refresh_token")
            // navigate("/")
        } catch (e) {
            onError(e)
        } finally {
            props.handleCancel()
            setLoading(false)
        }
    };
    return (
        <>
            <Modal
                className="modal"
                open={props.open}
                title={
                    <div className="modal-head">
                        Change Password
                    </div>
                }
                onCancel={props.handleCancel}
                footer={<></>}
            >
                    <Form form={form} onFinish={onSubmit}>
                        <Form.Item
                                name="oldPassword"
                                label="Current Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your current password!',
                                        type: "password"
                                    },
                                ]}
                            >
                                <Input.Password
                                    size="large"
                                    placeholder="Password"/>
                            </Form.Item>
                        <div className='merge-fields'>
                            <Form.Item
                                name="newPassword"
                                label="New Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input new password!',
                                        type: "password"
                                    },
                                ]}
                            >
                                <Input.Password
                                    size="large"
                                    placeholder="Password"/>
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                label="Confirm New Password"
                                dependencies={['password']}
                                // hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm new password!',
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('passwords not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    placeholder="Confirm new password"
                                    size="large"/>
                            </Form.Item>
                        </div>
                        {/* <div className="merge-fields">
                            <Button loading={loading} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </div> */}
                        <div className="modal-footer">
                            <button
                                className="secondary_btn"
                                // key="back"
                                onClick={props.handleCancel}
                            >
                                Cancel
                            </button>
                            <button
                                loading={loading}
                                className="primary_btn"
                                htmlType="submit"
                                // key="back"
                                // onClick={cancelRegistrationForm}
                            >
                            {loading?<LoadingSpinner/>:"Change Password"

                            }
                            </button>
                        </div>
                    </Form>
            </Modal>
        </>
    );
};

export default ResetPassword;
