import React from 'react';
import "./About.css";


const Secretariat = () => {
  return (
    <div className="about-main-page">
      {/* <h1>The Secretariat</h1> */}
      <div className='about-main'>
          <h3 className="ribbon">The Secretariat</h3>
          <p className='about-content'>
              The Nairobi County Public Service board is supported by 17 members of secretariat. The highly qualified 
              officers offer day to day administrative support to the board for optimal performance. 
            </p>
            {/* <p className='about-content'>
              The Nairobi County Public Service Board secretariat is supported by a team of 17 highly 
              qualified secretariat members. These officers provide day to day administrative support 
              to the board, helping to streamline its operations and enable it to achieve its mandate 
              effectively.
            </p>
            <p className='about-content'>
              The secretariat members' roles include coordinating board meetings, ensuring board members 
              are well-prepared for their duties, and maintaining records of board activities. They also 
              play a crucial role in liaising with other stakeholders in the county to ensure the board's 
              decisions are well-informed and aligned with the county's development goals. 
            </p>
            <p className='about-content'>
              In addition, the secretariat members are responsible for managing the board's resources, 
              including finances and human resources, ensuring transparency and accountability in the 
              board's operations.
            </p> */}
      </div>
    </div>
  )
}

export default Secretariat


