import axios from '../libs/axios';

class ChangePasswordService {
    constructor() {
        this.contentType='application/password.change.v1+json'
    }
    update(payload){
        return axios.put(`/users/profile/password`, payload, {
            headers: {
                'Content-Type':this.contentType
            }
        });
    }
}

export default new ChangePasswordService();