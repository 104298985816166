import React from 'react'
import HeadCarousel from '../../Components/HeadCarousel/HeadCarousel';
import HomeUpdateCard from '../../Components/HomeSectionCard/HomeSectionCard';
import HomeNews from './HomeNews';
import HomeNotices from './HomeNotices';
import HomeShortlists from './HomeShortlists';
// import LatestUpdates from './LatestUpdates';
// import HomeNoticeShortlists from './HomeNoticeShortlists';
// import HomeVaccencies from './HomeVacancies';
// import WelcomeNote from './WelcomeNote';

const HomePage = () => {
  return (
    <div className='home-main'>
      <HeadCarousel/>
      <HomeNotices/>
      <HomeShortlists/>
      <HomeNews/>
      {/* <LatestUpdates/> */}
      {/* <HomeNoticeShortlists/> */}
      {/* <HomeVaccencies/> */}
      {/* <WelcomeNote/> */}
    </div>
  )
}

export default HomePage;