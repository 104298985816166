import React from 'react';

import Image1 from "../../assets/images/head-banners/banner1.jpg";
import Image2 from "../../assets/images/head-banners/banner2.jpg";
import Image3 from "../../assets/images/head-banners/banner4.jpg";


export const HeadCarouselData=[
    // {
    {
        id:2,
        image:Image3,
        // caption:"here should be the caption ",
    },
        {
            id:0,
            image:Image1,
            // caption:"here should be the caption ",
        },
    {
        id:1,
        image:Image2,
        // caption:"here should be the caption ",
    },

    // {
    //     id:1,
    //     image:Image1,
    //     // caption:"here should be the caption ",
    // },
    //     id:1,
    //     image:Image1,
        // caption:"here should be the caption 1",
    // },
    // {
    //     id:2,
    //     image:Image2,
    //     caption:"here should be the caption ",
    // },
    // {
    //     id:3,
    //     image:Image3,
    //     caption:"here should be the caption 3",
    // },
    // {
    //     id:4,
    //     image:Image4,
        // caption:"here should be the caption 4",
    // },
    // {
    //     id:5,
    //     image:Image5,
        // caption:"here should be the caption 5",
    // },
]
