export const MenuData=[
    {
        key:1,
        title:"home",
        path:"/",
    },
    {
        key:2,
        title:"About CPSB",
        path:"about-us",
        subMenu:[
            {
                key:1,
                title:"Board's Mandate",
                path:"about/board-mandate",
            },
            // {
            //     key:2,
            //     title:"Board Committees",
            //     path:"about/board-committies",
            // },
            // {
            //     key:3,
            //     title:"Vision, Mission & Core Values",
            //     path:"about/vission-mission-coreValues",
            // },
            {
                key:4,
                title:"Service Charter",
                path:"about/service-charter",
            },{
                key:5,
                title:"Board Members",
                path:"about/board-members",
            },
            {
                key:6,
                title:"The Secretariat",
                path:"about/secretariat",
            },
            // {
            //     key:7,
            //     title:"Media Centre",
            //     path:"about/media-centre",
            // }
        ]
    },
    {
        key:3,
        title:"Vacancies",
        path:"vacancies",
    },
    {
        key:4,
        title:"Notices",
        path:"notices"
    },
    {
        key:5,
        title:"Shortlists",
        path:"short-lists"
    },
    {
        key:6,
        title:"News",
        path:"news"
    },

    // {
    //     key:7,
    //     title:"FAQ",
    //     path:"frequently-asked-questions"
    // },
    {
        key:7,
        title:"Contact Us",
        path:"contact-us"
    },
    // {
    //     key:9,
    //     title:"Manage My Profile",
    //     path:"job-profile"
    // },
    // {
    //     key:10,
    //     title:<button className="primary_btn">Register/Login</button>,
    //     path:"#"
    // },
]