import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import {onError} from '../../libs/errorLib';
import DocumentService from '../../services/document.service';
import NewsService from '../../services/news.service';
import NoticesService from '../../services/notices.service';
import ShortlistsService from '../../services/shortlists.service';
import DocumentList from './Document/HomeSectionDocument';
import "./HomeSectionDetailPage.css";
import parser from "html-react-parser"

const HomeUpdateDetailPage = () => {
    const {type, id} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isImageLoading, setIsImageLoading] = useState(false);
    const [dataSource, setDataSource] = useState('');
    const [imageSrc, setImageSrc] = useState('');
    const [imageID, setImageID] = useState('');
    useEffect(() => {
        getDetails(id, type)
        getimage(imageID)
    }, [imageID, id]);
    const getDetails = async (id, type) => {
        setIsLoading(true);
        try {
            let response;
            if (type === "news") {
                response = await NewsService.getSingle(id)
            } else if (type === "notices") {
                // console.log("notices")
                response = await NoticesService.getSingle(id)
            } else if (type === "shortlists") {
                response = await ShortlistsService.getSingle(id)
            }

            setDataSource(response);
            if (response.bannerImageId) {
                setImageID(response?.bannerImageId)
            } else if (response.bannerImage) {
                setImageID(response.bannerImage.docId)
            }

            // console.log("detail page data source", response)
            // getimage(imageID)
        } catch (e) {
            onError(e)
        } finally {
            setIsLoading(false)
        }
    }


    const getimage = async () => {
        setIsImageLoading(true)
        let response;
        if (type === "news") {
            response = await DocumentService.getNews(imageID)
        } else if (type === "notices") {
            response = await DocumentService.getNotices(imageID)
        } else if (type === "shortlists") {
            response = await DocumentService.getShortlists(imageID)
        }
        setIsImageLoading(false)
        setImageSrc(URL.createObjectURL(response))
    }
    // console.log("detail page data source",response)


    return (

        <div className='home-update-detailpage'>
            {isLoading ? <LoadingSpinner/> :
                <>
                    {/* {isImageLoading ?
                        <LoadingSpinner/> :
                        <div className="home_update-detail_image">
                            <img src={imageSrc} alt="image"/>
                        </div>
                    } */}
                    <div className='details'>
                        <h2>{dataSource?.title}</h2>
                        {dataSource.created &&
                        <h5>Created on {moment(dataSource?.created).format("dddd, DD MMMM YYYY hh:mm a")}</h5>
                        }
                        <h4>{dataSource?.status}</h4>
                        {
                            dataSource.content &&    <p>{parser(`${dataSource?.content}`)}</p>
                        }

                        {type=="notices" && dataSource?.noticeDownloadId &&
                            <>
                                <DocumentList type='notice' doc={dataSource} download={dataSource.noticeDownloadId}/>
                            </>
                        }
                        {type=="shortlists" && dataSource?.shortlistDownloadId &&
                            <DocumentList type='shortlist' doc={dataSource} download={dataSource.shortlistDownloadId}/>
                        }
                    </div>
                </>
            }

        </div>
    )
}

export default HomeUpdateDetailPage