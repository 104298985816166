import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Routers from "./Routers/Routes";
import ScrollToTop from "./ScrollToTop";



function App() {
  return (
    <div className="App">
      <Navbar />
      <ScrollToTop>  
        <Routers />
      </ScrollToTop>
      <Footer />
      
      
    </div>
  );
}

export default App;
