import axios from '../libs/axios';

class DocumentService {
    constructor() {
        this.contentType='multipart/form-data'
    }

    singleFileUpload(payload) {
        // console.log(payload)
        return axios.post(`documents/upload`, payload,{
            headers:{
                'Content-Type':this.contentType
            }
        });
    }
    
    get(documentId){
        return axios.get(`/documents/${documentId}`, {
            responseType: 'blob',
        });
    }

    getNews(documentId){
        return axios.get(`/documents/news/${documentId}`, {
            responseType: 'blob',
        });
    }
    
    getNotices(documentId){
        return axios.get(`/documents/notices/${documentId}`, {
            responseType: 'blob',
        });
    }
    
    getShortlists(documentId){
        return axios.get(`/documents/shortlists/${documentId}`, {
            responseType: 'blob',
        });
    }

}

export default new DocumentService();
