import React from 'react'

const MediaCentre = () => {
  return (
    <div className="about-main-page">
        <h1>Media Centre</h1>
        <div className='about-main'>
            <h3 className="ribbon">News</h3>
            <p className='about-content'>
                There is not any news right now.
            </p>
        </div>
        <div className='about-main'>
            <h3 className="ribbon">Notices</h3>
            <p className='about-content'>
                There is not any Notice(s) right now.
            </p>
        </div>
        <div className='about-main'>
            <h3 className="ribbon">Image Gallery</h3>
            <p className='about-content'>
                There is not any Image right now.
            </p>
        </div>
    </div>
  )
}

export default MediaCentre