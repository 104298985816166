import axios from './../libs/axios';

class JobProfileService {
    constructor() {
        this.contentType = 'application/job.profile.v1+json'
        this.baseUrl = "job/profiles"
    }

    get() {
        return axios.get(this.baseUrl, {});
    }

    updateProfile(payload) {
        return axios.put(this.baseUrl, payload, {
            headers: {
                "Content-Type": this.contentType
            }
        })
    }
    downloadDocument(documentId){
        return axios.get(`documents/${documentId}`)
    }
}

export default new JobProfileService();