import React from "react";
import {message} from 'antd';

export function onError(error) {
    if (error.response?.headers['error-alert']) {
        message.error(error.response?.headers['error-alert']);
    } else if (error.response?.data?.error_description) {
        message.error(error.response?.data?.error_description);
    } else if (error.message && !error.response?.data?.error_description) {
        message.error(error.message);
    } else {
        message.error("Cant complete request");

    }
}
