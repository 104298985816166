import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HomeUpdateCard from '../../Components/HomeSectionCard/HomeSectionCard';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import { onError } from '../../libs/errorLib';
import ShorlistServices from '../../services/shortlists.service';
import "./HomePage.css";


const HomeShortlists = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        getShortlists()
    }, []);
    const getShortlists = async () => {
        setIsLoading(true);
        try {
            const response = await ShorlistServices.getList()
            // console.log("home shortlists list ",response)
            const sortedData = response?.results?.sort((a, b) => {
                let A = new Date(a.created)
                let B = new Date(b.created)
                return A - B;
            })
            setDataSource(sortedData);
        } catch (e) {
            onError(e)
        } finally {
            setIsLoading(false)
        }

    }
    return (
        <div className="home_updates_main">
            <h2 className='theme-h2'>Shortlists</h2>
            {isLoading ? <LoadingSpinner /> :
                <>
                    {dataSource.length ?
                        <>
                            <div className="home-updates">
                                {
                                    dataSource?.map((item, index) => index < 3 &&
                                        <HomeUpdateCard
                                            imageID={item.bannerImageId}
                                            created={item.created}
                                            title={item.title}
                                            status={item.status}
                                            content={item.content}
                                            id={item.id}
                                            type="shortlists"
                                        />)
                                }
                            </div>
                            <div className='home_view_button'>
                                <Link to="/short-lists" className='primary_btn'>View All Shortlists</Link>
                            </div>
                        </> :
                        <div style={{ textAlign: "center" }}>No Latest Shortlists</div>
                    }
                </>
            }
        </div>
    )
}

export default HomeShortlists