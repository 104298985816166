import React, {useEffect, useState} from "react";
import "./JobProfile.css";
import {Checkbox, DatePicker, Form, Input, Select, Upload} from "antd";
import FormOptions from "./JobFormOptions";
import AddDegreeModal from "./AddDegreeModal";
import * as BiIcons from "react-icons/bi";
import AddEmploymentModal from "./AddEmploymentModal";
import JobProfileService from "../../services/jobProfile.service"
import JobOpeningService from "../../services/jobOpening.service"
import LocationService from "../../services/location.service"
import JobApplicationService from "../../services/jobApplication.service"
import {onError} from "../../libs/errorLib";
import {onSucess} from "../../libs/onSuccess";
import dayjs from 'dayjs'
import config from "../../config/index"
import {getCookie} from "../../libs/cookie";
import {useParams} from "react-router";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import fileDownload from "js-file-download";
import SingleFileUpload from "../../Components/FileUpload/SingleFileUpload";
import { v4 as uuid } from "uuid";

const JobProfile = () => {
  const [form] = Form.useForm();
  const { jobId } = useParams();
  //  ---------Add Degree Modal-----
  const [addDegreeOpen, setAddDegreeOpen] = useState(false);
  const [secondaryQualifications, setSecondaryQualifications] = useState([]);
  const [employemnentHistory, setEmployementHistory] = useState([]);
  const [history, setHistory] = useState(null);
  const [qualification, setQualification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [resumeId, setResumeId] = useState("");
  // -------------Clearancess --------------
  const [kraClearance, setKraClearance] = useState(true);
  const [helbClearance, setHelbClearance] = useState(true);
  const [goodConduct, setGoodConduct] = useState(true);
  const [eaccClearance, setEaccClearance] = useState(true);
  const [crbClearance, setCrbClearance] = useState(true);
  // -------------Clearancess ends --------------
  const [resumeName, setResumeName] = useState("");
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [defaultOtherFileList, setDefaultOtherFileList] = useState([]);
  const [otherAttachments, setOtherAttachments] = useState([]);
  const [job, setJob] = useState(null);

  const [countryId, setCountryId] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [wardOptions, setWardOptions] = useState([]);
  const [subCountryOptions, setSubCountryOptions] = useState([]);

  const [residenceCountryId, setResidenceCountryId] = useState(null);
  const [residenceWardOptions, setResidenceWardOptions] = useState([]);
  const [residenceSubCountryOptions, setResidenceSubCountryOptions] = useState(
    []
  );
  const [iframe, setIframe] = useState(null);
  useEffect(() => {
    getJobProfile();
    getCountries();
  }, []);
  const getCountries = async () => {
    try {
      const response = await LocationService.getCountries();
      const options = response.map((option) => {
        return { id: option.id, value: option.name, name: option.name };
      });
      setCountryOptions(options);
    } catch (e) {
      onError(e);
    }
  };
  useEffect(() => {
    if (jobId) {
      getJob();
    }
  }, [jobId]);
  const getJob = async () => {
    try {
      const response = await JobOpeningService.getJob(jobId);
      setJob(response);
    } catch (e) {
      onError(e);
    } finally {
    }
  };
  const openAddDegreeModal = (e, qualification) => {
    setAddDegreeOpen(true);
    setQualification(qualification);
  };
  const closeAddDegreeModal = () => {
    setAddDegreeOpen(false);
  };

  //  ---------Add Employment Modal-----
  const [addEmploymentOpen, setAddEmploymentOpen] = useState(false);
  const openAddEmploymentModal = (e, history) => {
    setHistory(history);
    setAddEmploymentOpen(true);
  };
  const closeAddEmploymentModal = () => {
    setAddEmploymentOpen(false);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    
    try {

      const user = {
        salutation: values.salutation,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        nationalId: values.nationalId,
        gender: values.gender,
        birthdate: values.birthdate,
        email: values.email,
        mobileNumber: values.mobileNumber,
        postalAddress: values.postalAddress,
        postalCode: values.postalCode,
        maritalStatus: values.maritalStatus,
        religion: values.religion,
        ethnicity: values.ethnicity,
        anyDisability: values.anyDisability,
        homeCounty: values.homeCounty,
        homeSubCounty: values.homeSubCounty,
        homeWard: values.homeWard,
        residenceCounty: values.residenceCounty,
        residenceSubCounty: values.residenceSubCounty,
        residenceWard: values.residenceWard,
      };
      const secondary = {
        secondarySchool: values.secondarySchool,
        grade: values.grade,
        yearOfCompletion: dayjs(values.yearOfCompletion).year(),
        mathematics: values.mathematics,
        english: values.english,
        kiswahili: values.kiswahili,
        biology: values.biology,
        chemistry: values.chemistry,
        physics: values.physics,
      };
      const postSecondaryQualifications = secondaryQualifications;
      const employmentHistories = employemnentHistory;
      const coverLetterAndClearance = {
        coverLetter: values.coverLetter,
        kraClearance: kraClearance,
        helbClearance: helbClearance,
        eaccClearance: eaccClearance,
        crbClearance: crbClearance,
        goodConduct: goodConduct,
      };
      const data = {
        user,
        secondary,
        postSecondaryQualifications,
        employmentHistories,
        coverLetterAndClearance,
        resumeId,
        otherAttachments,
      };


      await JobProfileService.updateProfile(data);
      onSucess("Profile updated successfully.");
      submitApplication();
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const getJobProfile = async () => {
    setLoadingPage(true);
    try {
      const response = await JobProfileService.get();
      console.log("job Profile", response);
      response.secondary.yearOfCompletion = dayjs().year(
        response.secondary.yearOfCompletion
      );
      response.user.birthdate = dayjs(response.user.birthdate);
      const secondary = response.postSecondaryQualifications.map((qual) => {
        return {
          courseName: qual.courseName,
          institution: qual.institution,
          status: qual.status,
          yearOfCompletion: qual.yearOfCompletion,
          yearOfStart: qual.yearOfStart,
          id: Math.floor(Math.random() * 100),
        };
      });
      setSecondaryQualifications(secondary);
      const history = response.employmentHistories.map((qual) => {
        return {
          currentJob: qual.currentJob,
          employerName: qual.employerName,
          jobDescription: qual.jobDescription,
          jobTitle: qual.jobTitle,
          periodFrom: qual.periodFrom,
          periodTo: qual.periodTo,
          id: Math.floor(Math.random() * 100),
        };
      });
      setEmployementHistory(history);
      form.setFieldsValue(response.user);
      form.setFieldsValue(response.secondary);
      form.setFieldsValue(response.coverLetterAndClearance);
      // -----------set Clearances-----------
      setKraClearance(response.coverLetterAndClearance.kraClearance);
      setHelbClearance(response.coverLetterAndClearance.helbClearance);
      setEaccClearance(response.coverLetterAndClearance.eaccClearance);
      setCrbClearance(response.coverLetterAndClearance.crbClearance);
      setGoodConduct(response.coverLetterAndClearance.goodConduct);
      // -----------set Clearances end-----------
      setResumeName(response?.resume?.filename);
      setResumeId(response?.resume?.docId);
      // if (response.resume) {
      //     setDefaultFileList(old => [...old, {
      //         uid: '1',
      //         name: response.resume.filename,
      //         status: 'completed',
      //     }])
      //     setResumeId(response.resume.docId)
      // }
      if (response.otherAttachments.length) {
        response.otherAttachments.map((att) => {
          setDefaultOtherFileList((old) => [
            ...old,
            {
              uid: att.docId,
              name: att.filename,
              status: "completed",
            },
          ]);
        });
        const otherAttachments = response.otherAttachments.map((att) => {
          return att.docId;
        });
        setOtherAttachments(otherAttachments);
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoadingPage(false);
    }
  };


  const addQualification = (values, id = null) => {
    if (id) {
      const secondary = secondaryQualifications.filter((sec) => sec.id !== id);
      setSecondaryQualifications(secondary);
      setQualification(null);
      values.id = id;
    } else {
      const unique_id = uuid();
      values.id = unique_id;
    }
    setSecondaryQualifications((oldArray) => [...oldArray, values]);
    setAddDegreeOpen(false);
  };

  const deleQualification = (e, qual) => {
    const secondary = secondaryQualifications.filter(
      (sec) => sec.id !== qual.id
    );
    setSecondaryQualifications(secondary);
  };

  const addHistory = (values, id = null) => {
    if (id) {
      const secondary = employemnentHistory.filter((sec) => sec.id !== id);
      setEmployementHistory(secondary);
      setHistory(null);
      values.id = id;
    } else {
      const unique_id = uuid();
      values.id = unique_id;
    }
    setEmployementHistory((oldArray) => [...oldArray, values]);
    setAddEmploymentOpen(false);
  };

  const deleteHistory = (e, history) => {
    const secondary = employemnentHistory.filter(
      (sec) => sec.id !== history.id
    );
    setEmployementHistory(secondary);
  };

  // const props = {
  //     name: 'file',
  //     action: config.API_BASE_URL + 'documents/upload',
  //     headers: {
  //         authorization: 'Bearer ' + getCookie("access_token"),
  //     },
  //     onChange(info) {
  //         console.log(info)
  //         if (info.file.status !== 'uploading') {
  //             console.log(info.file, info.fileList);
  //         }
  //         if (info.file.status === 'done') {
  //             setResumeId(info.file.response)
  //             onSucess(`${info.file.name} file uploaded successfully`);
  //         } else if (info.file.status === 'error') {
  //             onError(`${info.file.name} file upload failed.`);
  //         } else if (info.file.status === 'removed') {
  //             setResumeId("")
  //         }
  //     },
  //     defaultFileList: defaultFileList,
  //     onDownload(data){
  //         alert()
  //     }
  // };

  const propsOther = {
    name: "file",
    action: config.API_BASE_URL + "documents/upload",

    headers: {
      authorization: "Bearer " + getCookie("access_token"),
    },

    onChange(info) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setOtherAttachments((old) => [...old, info.file.response]);
        onSucess(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        onError(`${info.file.name} file upload failed.`);
      } else if (info.file.status === "removed") {
        const uid = info.file.uid;
        let array = otherAttachments;
        array = array.filter((id) => id !== uid);
        setOtherAttachments(array);
      }
    },
    defaultFileList: defaultOtherFileList,
  };

  const submitApplication = async () => {
    setLoading(true);
    if (jobId) {
      try {
        const data = {
          jobOpeningId: jobId,
        };
        await JobApplicationService.submitApplication(data);
        onSucess("Application Submitted Successfully.");
      } catch (e) {
        // console.log(e)
        onError(e);
      } finally {
        setLoading(false);
      }
    }
  };


  const download = async () => {
    let url =
      "http://209.145.52.241:8080/cpsb/documents/548213e7-fd49-429d-a528-eb5c664041cc";
    fetch(url, {
      headers: {
        Authorization: `Bearer ${getCookie("access_token")}`,
      },
    })
      .then((response) => response.blob())
      .then((response) => {
        // console.log('response',response)
        const blob = new Blob([response], { type: response.type });
        const link = document.createElement("a");
        link.download = "some";
        link.href = URL.createObjectURL(blob);
        setIframe(link.href);

        link.click();
      });
  };

  const onChangeHomeCountry = async (country) => {
    const countryObject = countryOptions.find((c) => c.name === country);
    setCountryId(countryObject.id);
    const response = await getSubCounty(countryObject.id);
    const options = response.map((option) => {
      return { id: option.id, value: option.name, name: option.name };
    });
    setSubCountryOptions(options);
  };
  const onChangeSubCounty = async (country) => {
    const countryObject = subCountryOptions.find((c) => c.name === country);
    const response = await getWard(countryId, countryObject.id);
    const options = response.map((option) => {
      return { id: option.id, value: option.wardName, name: option.wardName };
    });
    setWardOptions(options);
  };

  const onChangeResidenceCountry = async (country) => {
    const countryObject = countryOptions.find((c) => c.name === country);
    setResidenceCountryId(countryObject.id);
    const response = await getSubCounty(countryObject.id);
    const options = response.map((option) => {
      return { id: option.id, value: option.name, name: option.name };
    });
    setResidenceSubCountryOptions(options);
  };
  const onChangeResidenceSubCounty = async (country) => {
    const countryObject = residenceSubCountryOptions.find(
      (c) => c.name === country
    );
    const response = await getWard(residenceCountryId, countryObject.id);
    const options = response.map((option) => {
      return { id: option.id, value: option.wardName, name: option.wardName };
    });
    setResidenceWardOptions(options);
  };

  const getSubCounty = async (id) => {
    try {
      return await LocationService.getSubCountries(id);
    } catch (e) {
      onError(e);
    }
  };
  const getWard = async (countryId, id) => {
    try {
      return await LocationService.getWards(countryId, id);
    } catch (e) {
      onError(e);
    }
  };
  const setResume = (id) => {
    setResumeId(id);
  };
  return (
    <div className="job-profile-page">
      <div className="job-profile-main">
        <div className="job-form-detals">
          {!loadingPage ? (
            <Form
              form={form}
              onFinish={onSubmit}
              onFinishFailed={onFinishFailed}
            >
              {jobId && job && (
                <>
                  <h2 className="theme-h2">Application For {job.title}</h2>
                  <li>Application Deadline: {job.applicationDeadline}</li>
                  <li>Location: {job.location}</li>
                  <li>Number of Positions: {job.numberOfPositions}</li>
                </>
              )}
              {/* Personal Information */}
              <h2 className="theme-h2">Personal Information</h2>

              {/*{iframe && <iframe width="100%" src={iframe} /> }*/}
              <div className="merge-fields">
                <Form.Item
                  className="input-25"
                  label="Salutation"
                  name="salutation"
                >
                  <Select
                    showSearch
                    placeholder="Select a person"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={FormOptions[0].salutationOptions}
                  />
                </Form.Item>
                <Form.Item
                  className="input-25"
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                        required: true,
                        message: "First Name is required!",
                    },
                ]}
                >
                  <Input type="text" placeholder="First Name" />
                </Form.Item>
                <Form.Item
                  className="input-25"
                  label="Middle Name"
                  name="middleName"
                >
                  <Input type="text" placeholder="Middle Name" />
                </Form.Item>
                <Form.Item
                  className="input-25"
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                        required: true,
                        message: "Last Name is required!",
                    },
                ]}
                >
                  <Input type="text" placeholder="last Name" />
                </Form.Item>
              </div>

              <div className="merge-fields">
                <Form.Item
                  className="input-25"
                  label="National ID"
                  name="nationalId"
                >
                  <Input
                    type="text"
                    placeholder="National ID"
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item className="input-25" label="Gender" name="gender">
                  <Select
                    showSearch
                    placeholder="Select a Gender"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={FormOptions[1].genderOptions}
                  />
                </Form.Item>
                <Form.Item
                  className="input-25"
                  label="Date of Birth"
                  name="birthdate"
                  rules={[
                    {
                        required: true,
                        message: "Birth Date is required!",
                    },
                  ]}
                >
                  <DatePicker />
                  {/*<Input type="date" placeholder="Date"/>*/}
                </Form.Item>
                <Form.Item className="input-25" label="Email" name="email">
                  <Input type="email" placeholder="Email" disabled={true} />
                </Form.Item>
              </div>

              <div className="merge-fields">
                <Form.Item
                  className="input-25"
                  label="Mobile Number"
                  name="mobileNumber"
                >
                  <Input
                    type="text"
                    placeholder="Mobile Number"
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  className="input-25"
                  label="Postal Address"
                  name="postalAddress"
                  rules={[
                    {
                        required: true,
                        message: "Postal Address is required!",
                    },
                  ]}
                >
                  <Input type="text" placeholder="Postal Address" />
                </Form.Item>
                <Form.Item
                  className="input-25"
                  label="Postal Code"
                  name="postalCode"
                  rules={[
                    {
                        required: true,
                        message: "Postal Code is required!",
                    },
                  ]}
                >
                  <Input type="text" placeholder="Postal Code" />
                </Form.Item>

                <Form.Item
                  className="input-25"
                  label="Marital Status"
                  name="maritalStatus"

                >
                  <Select
                    showSearch
                    placeholder="Marital Status"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={FormOptions[2].maritalOptions}
                  />
                </Form.Item>
              </div>

              <div className="merge-fields">
                <Form.Item
                  className="input-25"
                  label="Religion"
                  name="religion"
                >
                  <Select
                    showSearch
                    placeholder="Religion"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={FormOptions[3].religionOptions}
                  />
                </Form.Item>
                <Form.Item
                  className="input-25"
                  label="Ethnicity"
                  name="ethnicity"
                >
                  <Select
                    showSearch
                    placeholder="Ethnicity"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={FormOptions[4].ethnicityOptions}
                  />
                </Form.Item>
                <Form.Item
                  className="input-25"
                  label="Any Disability"
                  name="anyDisability"
                >
                  <Select
                    showSearch
                    placeholder="Disability"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={FormOptions[5].disabilityOptions}
                  />
                </Form.Item>
              </div>

              <div className="merge-fields">
                <Form.Item
                  className="input-25"
                  label="Home Country"
                  name="homeCounty"
                  rules={[
                    {
                        required: true,
                        message: "Home county is required!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Home Country"
                    optionFilterProp="children"
                    onChange={onChangeHomeCountry}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={countryOptions}
                  />
                </Form.Item>
                <Form.Item
                  className="input-25"
                  label="Home Sub Country"
                  name="homeSubCounty"
                  rules={[
                    {
                        required: true,
                        message: "Home subcounty is required!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Home Sub Country"
                    optionFilterProp="children"
                    onChange={onChangeSubCounty}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={subCountryOptions}
                  />
                </Form.Item>
                <Form.Item
                  className="input-25"
                  label="Home Ward"
                  name="homeWard"
                  rules={[
                    {
                        required: true,
                        message: "Home ward is required!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Home Ward"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={wardOptions}
                  />
                </Form.Item>
              </div>
              <div className="merge-fields">
                <Form.Item
                  className="input-25"
                  label="Residence Country"
                  name="residenceCounty"
                  rules={[
                    {
                        required: true,
                        message: "Residence county is required!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Residence Country"
                    optionFilterProp="children"
                    onChange={onChangeResidenceCountry}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={countryOptions}
                  />
                </Form.Item>
                <Form.Item
                  className="input-25"
                  label="Residence Sub Country"
                  name="residenceSubCounty"
                  rules={[
                    {
                        required: true,
                        message: "Residence subcounty is required!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Residence Sub Country"
                    optionFilterProp="children"
                    onChange={onChangeResidenceSubCounty}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={residenceSubCountryOptions}
                  />
                </Form.Item>
                <Form.Item
                  className="input-25"
                  label="Residence Ward"
                  name="residenceWard"
                  rules={[
                    {
                        required: true,
                        message: "Residence ward is required!",
                    },
                  ]}
                >
                  <Select
                    showSearch="false"
                    placeholder="Residence Ward"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={residenceWardOptions}
                  />
                </Form.Item>
              </div>
              {/* Academic Details A: Secondary */}
              <h2 className="theme-h2">Academic Details A: Secondary</h2>
              <div className="merge-fields">
                <Form.Item
                  className="input-25"
                  label="Secondary School"
                  name="secondarySchool"
                  rules={[
                    {
                        required: true,
                        message: "Secondary school name is required!",
                    },
                  ]}
                >
                  <Input type="text" placeholder="Secondary School" />
                </Form.Item>
                <Form.Item className="input-25" label="Grade" name="grade">
                  <Select
                    showSearch
                    name="kcseGrade"
                    placeholder="Grade"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={FormOptions[10].gradeOptions}
                    rules={[
                      {
                          required: true,
                          message: "Grade is required!",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item label="Year of Completion" name="yearOfCompletion">
                  <DatePicker picker="year" placeholder="Year of Completion" />
                </Form.Item>
              </div>

              <div className="merge-fields">
                <Form.Item
                  className="input-25"
                  label="Mathematic"
                  name="mathematics"
                  rules={[
                    {
                        required: true,
                        message: "Mathematics grade is required!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Mathematic grade"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={FormOptions[10].gradeOptions}
                  />
                </Form.Item>
                <Form.Item 
                   className="input-25" 
                   label="English" 
                   name="english"
                   rules={[
                    {
                        required: true,
                        message: "English grade is required!",
                    },
                  ]}
                   >
                  <Select
                    showSearch
                    placeholder="English grade"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={FormOptions[10].gradeOptions}
                  />
                </Form.Item>
                <Form.Item
                  className="input-25"
                  label="Kiswahili"
                  name="kiswahili"
                  rules={[
                    {
                        required: true,
                        message: "Kiswahili grade is required!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Kiswahili grade"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={FormOptions[10].gradeOptions}
                  />
                </Form.Item>
                <Form.Item className="input-25" label="Biology" name="biology">
                  <Select
                    showSearch
                    placeholder="Biology grade"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={FormOptions[10].gradeOptions}
                  />
                </Form.Item>
              </div>

              <div className="merge-fields">
                <Form.Item className="input-25" label="Physics" name="physics">
                  <Select
                    showSearch
                    placeholder="Physics grade"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={FormOptions[10].gradeOptions}
                  />
                </Form.Item>
                <Form.Item
                  className="input-25"
                  label="Chemistry"
                  name="chemistry"
                >
                  <Select
                    showSearch
                    placeholder="Chemistry grade"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={FormOptions[10].gradeOptions}
                  />
                </Form.Item>
              </div>

              {/* Academic Details B: Post Secondary Qualification */}
              <h2 className="theme-h2">
                Academic Details B: Post Secondary Qualification
              </h2>
              <div className="sub-heading-form">
                <button
                  type="button"
                  className="primary_btn"
                  onClick={openAddDegreeModal}
                >
                  <span>
                    <BiIcons.BiPlusMedical />
                  </span>{" "}
                  Add
                </button>{" "}
                PostSecondary Qualification
              </div>
              {secondaryQualifications ? (
                <div className="table-wrap">
                  <table className="history-list-table">
                    <tr>
                      <th>Qualification</th>
                      <th>School</th>
                      <th>Course</th>
                      <th>Start</th>
                      <th>End</th>
                      <th>Action</th>
                    </tr>
                    {secondaryQualifications?.map((qualification) => {
                      return (
                        <tr>
                          <td>{qualification.status}</td>
                          <td>{qualification.institution}</td>
                          <td>{qualification.courseName}</td>
                          <td>{qualification.yearOfStart}</td>
                          <td>{qualification.yearOfCompletion}</td>
                          <td>
                            <span
                              onClick={(e) =>
                                openAddDegreeModal(e, qualification)
                              }
                            >
                              <BiIcons.BiEdit /> edit
                            </span>
                            <span
                              onClick={(e) =>
                                deleQualification(e, qualification)
                              }
                            >
                              <BiIcons.BiX /> delete
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              ) : (
                <>Loading...</>
              )}

              {/* Employment History */}
              <h2 className="theme-h2">Employment History</h2>
              <div className="sub-heading-form">
                <button
                  type="button"
                  className="primary_btn"
                  onClick={openAddEmploymentModal}
                >
                  <span>
                    <BiIcons.BiPlusMedical />
                  </span>{" "}
                  Add
                </button>{" "}
                Employment History
              </div>
              <div className="table-wrap">
                <table className="history-list-table">
                  <tr>
                    <th>Company Name</th>
                    <th>Job Title</th>
                    <th>Current Job</th>
                    <th>Start date</th>
                    <th>End date</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                  {employemnentHistory?.map((history) => {
                    return (
                      <tr>
                        <td>{history.employerName}</td>
                        <td>{history.jobTitle}</td>
                        <td>{history.currentJob ? "Yes" : "No"}</td>
                        <td>{history.periodFrom}</td>
                        <td>{history.periodTo}</td>
                        <td>{history.jobDescription}</td>
                        <td>
                          <div className="record-action-btns">
                            <span
                              onClick={(e) =>
                                openAddEmploymentModal(e, history)
                              }
                            >
                              <BiIcons.BiEdit /> edit
                            </span>
                            <span onClick={(e) => deleteHistory(e, history)}>
                              <BiIcons.BiX /> delete
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>

              {/* Cover Letter and Clearance */}
              <h2 className="theme-h2">Cover Letter and Clearance</h2>
              <Form.Item 
               name="coverLetter"
               rules={[
                {
                    required: true,
                    message: "Cover letter is required!",
                },
              ]}>
                <Input.TextArea placeholder="Cover Letter"></Input.TextArea>
              </Form.Item>

              {/* <Form.Item> */}
              {/* <Checkbox.Group> */}
              <Checkbox
                onChange={(e) => setKraClearance(e.target.checked)}
                name="kraClearance"
                checked={kraClearance}
                style={{
                  lineHeight: "32px",
                }}
              >
                KRA Clearance
              </Checkbox>
              <Checkbox
                onChange={(e) => setHelbClearance(e.target.checked)}
                name="helbClearance"
                checked={helbClearance}
                style={{
                  lineHeight: "32px",
                }}
              >
                Helb Clearance
              </Checkbox>
              <Checkbox
                onChange={(e) => setEaccClearance(e.target.checked)}
                name="eaccClearance"
                checked={eaccClearance}
                style={{
                  lineHeight: "32px",
                }}
              >
                EACC Clearance
              </Checkbox>
              <Checkbox
                onChange={(e) => setCrbClearance(e.target.checked)}
                name="crbClearance"
                checked={crbClearance}
                style={{
                  lineHeight: "32px",
                }}
              >
                CRB Clearance
              </Checkbox>
              <Checkbox
                onChange={(e) => setGoodConduct(e.target.checked)}
                name="goodConduct"
                checked={goodConduct}
                style={{
                  lineHeight: "32px",
                }}
              >
                Good Conduct
              </Checkbox>
              {/* </Checkbox.Group> */}
              {/* </Form.Item> */}

              {/* Resume */}
              <h2 className="theme-h2">Resume</h2>
              <Form.Item
                name="upload_resume"
                extra="Must be .pdf"
                label="Upload resume"
                style={{ maxWidth: "300px" }}
                rules={[
                  {
                      required: false,
                      message: "Resume attachement is required!",
                  },
                ]}
              >
                {/* <Upload {...props} listType="document" maxCount={1}>
                                        <button type="button" className="secondary_btn" icon={""}>
                                            Click to upload
                                        </button>
                                    </Upload> */}
                <SingleFileUpload
                  type="general"
                  setFileId={setResume}
                  fileId={resumeId}
                  fileName={resumeName}
                  acceptType=".jpg,.png,.jpeg,.bmp,.tiff,.docx,.pdf"
                />
              </Form.Item>

              {/* Other Attachments */}
              <h2 className="theme-h2">Other Attachments</h2>
              <Form.Item
                name="upload_documents"
                label="Upload attachments"
                // valuePropName="fileList"
                // getValueFromEvent={normFile}
              >
                <Upload {...propsOther} listType="document">
                  <button type="button" className="secondary_btn" icon={""}>
                    Choose Other File(s)
                  </button>
                </Upload>
              </Form.Item>
              <Form.Item style={{ margin: "20px auto", textAlign: "center" }}>
                {loading ? (
                  <button
                    className="primary_btn"
                    htmlType="button"
                    disabled={true}
                  >
                    Loading...
                  </button>
                ) : (
                  <button className="primary_btn" htmlType="submit">
                    {jobId ? "Submit Application" : "Update Profile"}
                  </button>
                )}
              </Form.Item>
            </Form>
          ) : (
            <LoadingSpinner />
          )}
        </div>
        <AddDegreeModal
          addQualification={addQualification}
          handleCancel={closeAddDegreeModal}
          qualification={qualification}
          open={addDegreeOpen}
        />
        <AddEmploymentModal
          addHistory={addHistory}
          handleCancel={closeAddEmploymentModal}
          open={addEmploymentOpen}
          history={history}
        />
      </div>
    </div>
  );
};

export default JobProfile;
