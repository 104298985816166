import axios from './../libs/axiosCountries';

class JobProfileService {
    
    constructor() {
        // this.basePath = 'http://162.243.123.202:8180/api/v1'
    }

    getCountries() {
        return axios.get("/counties", {});
    }

    getSubCountries(id) {
        return axios.get(`/counties/${id}/sub-counties/`, {});
    }

    getWards(country_id,sub_country_id) {
        return axios.get(`/counties/${country_id}/sub-counties/${sub_country_id}/wards`, {});
    }

}

export default new JobProfileService();