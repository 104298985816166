import React from 'react';
import {Route,Routes} from "react-router-dom";
import HomePage from '../Containers/HomePage/HomePage';
import ErrorPage from '../Containers/ErrorPage/ErrorPage';
import ContactUs from '../Containers/ContactUs/ContactUs';
import About from '../Containers/About/About';
import ShortLists from '../Containers/ListingPages/ShortLists';
import Notices from '../Containers/ListingPages/Notices';
import Vacancies from '../Containers/ListingPages/Vacancies';
import FAQ from '../Containers/ListingPages/FAQ/FAQ';
import JobProfile from '../Containers/JobProfile/JobProfile';
import BoardCommitties from '../Containers/About/BoardCommitties';
import ServiceCharter from '../Containers/About/ServiceCharter';
import BoardMandate from '../Containers/About/BoardMandate';
import VissionMission from '../Containers/About/VissionMission';
import {useSelector} from "react-redux";
import AuthModal from '../Components/Navbar/AuthModal';
import MediaCentre from '../Containers/About/MediaCentre';
import PastVacancies from '../Containers/ListingPages/PastVacancies';
import JobApplications from '../Containers/JobApplications/JobApplications';
import HomeUpdateDetailPage from '../Containers/HomeSectionDetailPage/HomeSectionDetailPage';
import News from '../Containers/ListingPages/News';
import BoardMembers from '../Containers/About/BoardMembers';
import Secretariat from '../Containers/About/Secretriate';


const Routers =()=>{
        const {isLoggedIn} = useSelector(({auth}) => auth);

        return(
        <Routes>
            <Route exact path="/" element={<HomePage/>}/>
            <Route exact path="type/:type/:id" element={<HomeUpdateDetailPage/>}/>
            <Route exact path="/contact-us" element={<ContactUs/>}/>
            <Route exact path="/about-us" element={<About/>}/>
            <Route exact path="/about/vission-mission-coreValues" element={<VissionMission/>}/>
            <Route exact path="/about/board-mandate" element={<BoardMandate/>}/>
            <Route exact path="/about/board-members" element={<BoardMembers/>}/>
            <Route exact path="/about/secretariat" element={<Secretariat/>}/>
            <Route exact path="/about/board-committies" element={<BoardCommitties/>}/>
            <Route exact path="/about/service-charter" element={<ServiceCharter/>}/>
            <Route exact path="/about/media-centre" element={<MediaCentre/>}/>
            <Route exact path="/short-lists" element={<ShortLists/>}/>
            <Route exact path="/notices" element={<Notices/>}/>
            <Route exact path="/news" element={<News/>}/>
            <Route exact path="/vacancies" element={<Vacancies/>}/>
            <Route exact path="/vacancies/past-vacancies" element={<PastVacancies/>}/>
            {
                isLoggedIn &&
                <>
                    <Route exact path="/job-profile/:jobId?" element={<JobProfile/>}/>
                    <Route exact path="/my-job-applications" element={<JobApplications/>}/>
                </>
            }
            <Route exact path="/frequently-asked-questions" element={<FAQ/>}/>
            <Route path="*" element={<ErrorPage/>}/>
        </Routes>
    )
}
export default Routers;