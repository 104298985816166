import React, {useState} from "react";
import {Modal, Form, Input, Button} from "antd";
import {onError} from "../../libs/errorLib";
import AuthService from "../../services/auth.service";
import ForgotPasswordService from "../../services/forgotPassword.service";
import {onSucess} from "../../libs/onSuccess";
import {deleteCookie, setCookie} from "../../libs/cookie";
import {setLoginUser, setUser} from "../../Store/Auth/authSlice";
import {useDispatch, useSelector} from "react-redux";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Link from "antd/es/typography/Link";

const AuthModal = (props) => {
    const [registerForm, setRegisterForm] = useState(false);
    const [forgotForm, setForgotForm] = useState(false);
    const [forgotVerification, setForgotVerification] = useState(false);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const RegistrationForm = () => {
        setRegisterForm(true);
    };
    const cancelRegistrationForm = () => {
        setRegisterForm(false);
    };
    const onSubmit = async (values) => {
        setLoading(true)
        try {
            const response = await AuthService.register(values)
            onSucess("Registered successfully.")
            props.handleCancel()
        } catch (e) {
            onError(e)
        } finally {
            setLoading(false)
        }
    };
    const onLoginSubmit = async (values) => {
        setLoading(true);
        try {
            deleteCookie("access_token")
            deleteCookie("refresh_token")
            const {username, password} = values
            let data = {
                grant_type: "password",
                username,
                password
            };
            const result = await AuthService.login(data)
            dispatch(setLoginUser(result));
            onSucess('Login Successfully.')
            setCookie("access_token", result.access_token)
            setCookie("refresh_token", result.refresh_token)
            await getProfile()
            props.handleCancel()
        } catch (e) {
            onError(e)
        } finally {
            setLoading(false)
        }
    };
    const getProfile = async () => {
        try {
            const result = await AuthService.profile()
            // console.log(result)
            dispatch(setUser(result))
        } catch (e) {
            // console.log('e',e)
            onError(e)
        }

    }

    const openForgotForm = () => {
        setForgotForm(true);
    };
    const cancelForgotForm = () => {
        setForgotForm(false);
    };
    const onForgotSubmit = async (values) => {
        setLoading(true);
        try {
            // deleteCookie("access_token")
            // deleteCookie("refresh_token")
            const {email} = values
            let data = {
                // grant_type: "password",
                email,
                // password
            };
            const result = await ForgotPasswordService.getCode(data)
            // dispatch(setLoginUser(result));
            onSucess('Code Successfully sent to email.')
            setForgotVerification(true)
            // setCookie("access_token", result.access_token)
            // setCookie("refresh_token", result.refresh_token)
            // await getProfile()
        } catch (e) {
            onError(e)
        } finally {
            setLoading(false)
        }
    };

    const onUpdatePassword = async (values) => {
        setLoading(true);
        try {
            const {newPassword,verificationCode} = values
            let data = {
                // grant_type: "password",
                newPassword,
                verificationCode
            };
            const result = await ForgotPasswordService.updatePassword(data)
            onSucess('Password Update Successfully.')
            setForgotVerification(false)
            setForgotForm(false)
        } catch (e) {
            onError(e)
        } finally {
            setLoading(false)
        }
    };
    return (
        <>
            <Modal
                className="modal"
                open={props.open}
                title={
                    <div className="modal-head">
                        {registerForm ? "Register" :`${forgotForm ? "Reset Password" : "Login"}`}
                    </div>
                }
                onCancel={props.handleCancel}
                footer={<></>}
            >
                {registerForm ? (
                    <Form onFinish={onSubmit}>
                        <div className="merge-fields">
                            <Form.Item className="input-50" label="First Name" name="firstName" rules={[
                                {
                                    required: true,
                                },
                            ]}>
                                <Input type="text" placeholder="First Name"/>
                            </Form.Item>
                            <Form.Item className="input-50" label="Middle Name" name="middleName" rules={[
                                {
                                    required: true,
                                },
                            ]}>
                                <Input type="text" placeholder="Middle Name"/>
                            </Form.Item>
                        </div>
                        <div className="merge-fields">
                            <Form.Item className="input-50" label="Last Name" name="lastName" rules={[
                                {
                                    required: true,
                                },
                            ]}>
                                <Input type="text" placeholder="Last Name"/>
                            </Form.Item>
                            <Form.Item className="input-50" label="Mobile Number" name="mobileNumber" rules={[
                                {
                                    required: true,
                                },
                            ]}>
                                <Input type="number" placeholder="Mobile Number"/>
                            </Form.Item>
                        </div>
                        <div className="merge-fields">
                            <Form.Item className="input-50" label="Email" name="email" rules={[
                                {
                                    required: true,
                                    type: 'email'
                                },
                            ]}>
                                <Input type="email" placeholder="Email"/>
                            </Form.Item>
                            <Form.Item className="input-50" label="National Id" name="nationalId" rules={[
                                {
                                    required: true,
                                },
                            ]}>
                                <Input type="number" placeholder="National Id"/>
                            </Form.Item>
                        </div>
                        <div className='merge-fields'>
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                        type: "password"
                                    },
                                ]}
                            >
                                <Input.Password
                                    size="large"
                                    placeholder="Password"/>
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                label="Confirm Password"
                                dependencies={['password']}
                                // hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('passwords not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    placeholder="Confirm Password"
                                    size="large"/>
                            </Form.Item>
                        </div>
                        {/* <div className="merge-fields">
                            <Button loading={loading} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </div> */}
                        <div className="modal-footer">
                            <button
                                className="secondary_btn"
                                // key="back"
                                onClick={cancelRegistrationForm}
                            >
                                Login
                            </button>
                            <button
                                loading={loading}
                                className="primary_btn"
                                htmlType="submit"
                                // key="back"
                                // onClick={cancelRegistrationForm}
                            >
                            {loading?<LoadingSpinner/>:"Register"

                            }
                            </button>
                        </div>
                    </Form>
                ) : (
                    forgotForm?(
                        forgotVerification?
                            <Form onFinish={onUpdatePassword}>
                                {/* {console.log("forgot Verification",forgotVerification)} */}
                                <div className='merge-fields'>
                                    <Form.Item
                                        name="newPassword"
                                        label="New Password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                                type: "password"
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            size="large"
                                            placeholder="new password"/>
                                    </Form.Item>
                                    <Form.Item
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        dependencies={['newPassword']}
                                        // hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your password!',
                                            },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('newPassword') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('passwords not match!'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password
                                            placeholder="Confirm Password"
                                            size="large"/>
                                    </Form.Item>
                                </div>
                                <Form.Item
                                    name="verificationCode"
                                    placeholder="Verification"
                                    label="verificationCode"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your verfication code",
                                        },
                                    ]}
                                >
                                    <Input placeholder="verficationCode"/>
                                </Form.Item>
                                
                                <div style={{textAlign:"center"}}>
                                    
                                    <button
                                        loading={loading}
                                        className="primary_btn"
                                        // key="back"
                                        htmlType="submit"
                                        // onClick={props.handleCancel}
                                    >{
                                        loading?<LoadingSpinner/>:"Update Password"
                                    }
                                    </button>
                                </div>
                            </Form>:
                        <Form onFinish={onForgotSubmit}>
                            <Form.Item label="Email" name="email" rules={[
                                {
                                    required: true,
                                    type: 'email'
                                },
                            ]}
                            >
                                <Input type="email"
                                    placeholder="Enter email"/>
                            </Form.Item>
                            
                            <div className="modal-footer">
                                <button
                                    className="secondary_btn"
                                    onClick={cancelForgotForm}
                                >
                                    Login
                                </button>
                                <button
                                    loading={loading}
                                    className="primary_btn"
                                    htmlType="submit"
                                    // onClick={props.handleCancel}
                                >{
                                    loading?<LoadingSpinner/>:"Get Code"
                                }
                                </button>
                            </div>
                        </Form>
                    ):(
                    <Form onFinish={onLoginSubmit}>
                        <Form.Item label="Email" name="username" rules={[
                            {
                                required: true,
                                type: 'email'
                            },
                        ]}>
                            <Input type="email" placeholder="Email"/>
                        </Form.Item>
                        <Form.Item
                            // name="loginPassword"
                            placeholder="Password"
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your password!",
                                },
                            ]}
                        >
                            <Input.Password placeholder="Password"/>
                        </Form.Item>
                        <Link onClick={openForgotForm}> Forgot password? reset password now</Link>
                        {/* <div className="merge-fields">
                            <Button loading={loading} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </div> */}
                        {/* <div className="modal-footer"> */}
                        
                        <div className="modal-footer">
                            <button
                                className="secondary_btn"
                                // key="back"
                                onClick={props.handleCancel}
                            >
                                Back
                            </button>
                            <p onClick={RegistrationForm}>Register</p>
                            <button
                                loading={loading}
                                className="primary_btn"
                                // key="back"
                                htmlType="submit"
                                // onClick={props.handleCancel}
                            >{
                                loading?<LoadingSpinner/>:"Login"
                            }
                            </button>
                        </div>
                    </Form>)
                )}
            </Modal>
        </>
    );
};

export default AuthModal;
