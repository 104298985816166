import React from 'react';
import "./About.css";

const BoardCommitties = () => {
  return (
    <div className="about-main-page">
            <h1>Board Committees</h1>
        <div className='about-main'>
            <h3 className="ribbon">Committees General</h3>
                <ol className='about-content'>
                    <li>Board Committees are established and the members appointed by the Board to facilitate decision-making 
                        therefore assisting the Board in the execution of its duties, powers and authority.  These Committees 
                        will be established with formal written terms of reference.  Delegation of authority to Board Committees 
                        does not in any way mitigate or dissipate the discharge by the Board of its duties and responsibilities.
                    </li>
                    <li>There shall be transparency and full disclosure from the Committees to the full Board except where the 
                        Committee had been mandated otherwise by the Board.
                    </li>
                    <li>In the absence of the Chairman of a Committee at a meeting, the Members present shall elect one of their
                        own to chair the meeting.
                    </li>
                    <li>A Committee may invite the Chairman of the Board, other Board Members, or any other such person to attend 
                        a meeting if it so desires.
                    </li>
                    <li>Board Committees will observe the same rules of conduct and procedures as the Board.
                    </li>
                    <li>Board Committees will only represent the Board when so authorized.
                    </li>
                    <li>A Committee shall make whatever recommendations to the Board it deems appropriate on any area within its 
                        remit where action or improvement is needed.
                    </li>
                    <li>The purpose and membership of the Board Committees will be determined by the Board which sets the terms 
                        of reference thereof and may be amended with the approval of the Board.
                    </li>
                    <li>Committees will develop work plans every financial year and will be guided by these plans in their activities.
                    </li>
                    <li>Committees shall, at least once a year review their own performance, constitution, and terms of reference to 
                        ensure their effectiveness.
                    </li>
                    <li>Committee chairpersons will make formal reports in form of committee papers as appropriate to the Board at 
                        Board meetings.
                    </li>
                    <li>Committees will be entitled to seek any information they require from the Secretariat and elsewhere in 
                        order to perform their duties.
                    </li>
                    <li>The Board may appoint ad hoc Committees to address specific issues.
                    </li>
                </ol>
            <h3 className="ribbon">Role of the Board with regard to the Committees</h3>
                <div className='about-content'>
                    <p>The Board at a minimum will:</p>
                    <ol>
                        <li>Ensure that the composition of any Committee is appropriate for its purpose;</li>
                        <li>Determine whether the Committees are adhering to appropriate meeting schedules and agendas;</li>
                        <li>Ensure that the functions of each Committee are clearly defined; and</li>
                        <li>Ensure that the Committees perform as required and report their deliberations/recommendations regularly 
                            to the Board for approval/guidance.
                        </li>
                    </ol>
                </div>
            <h3 className="ribbon">Types of Board Committees</h3>
                <div className='about-content'>
                    <p>In accordance with the Act, the Board has established the following Committees;</p>
                    <ol>
                        <li>Finance and Budgeting Committee;</li>
                        <li>Governance Committee;</li>
                        <li>Human Resource Planning and Management Committee;</li>
                        <li>Discipline Committee;</li>
                        <li>Inter-governmental Relations and ICT Committee;</li>
                    </ol>
                </div>
            <h2>The Board Committees</h2>
            <h3 className="ribbon">Finance, and Budgeting Committee</h3>
                <div className='about-content'>
                    <p>The objective of the Committee is to consider all matters associated with the Board’s finances, 
                        policies, capacity building, and governance and operationalize Sections 59 (1) (d), (e), and (f) 
                        of the County Governments Act, 2012.
                    </p>
                    <p>The Committee comprises of three (3) members of the Board.  The board committee secretaries shall 
                        be appointed by the board from the secretariat. 
                    </p>
                    <p>The quorum necessary for the transaction of business shall be two (2) members of the Board.
                    </p>
                    <p>At the beginning of a financial year, the Committee will draw a timetable of its meetings for the 
                        particular year.  The Committee may however meet at such time as the business of the Committee shall require.
                    </p>
                    <p><b>The Committee’s main responsibilities and functions shall include;</b></p>
                        <ol>
                            <li>Budget development, management and implementation.</li>
                            <li>Developing the Board’s training needs assessment.</li>
                            <li>Developing and managing the Board’s training schedule.</li>
                            <li>Prepare annual report for delivery to the County Assembly pursuant to section 59(1) and (5) 
                                of the County Governments Act, 2012.
                            </li>
                            <li>Propose activities for promotion of the Values and Principles Articles 10 and 232.</li>
                            <li>Propose modalities for monitoring the extent to which the Values and Principles are complied with in 
                                the County Public Service.
                            </li>
                        </ol>  
                </div>
            <h3 className="ribbon">Human Resource Planning and Management Committee </h3>
                <div className='about-content'>
                    <p>The objective of the Committee is to consider all matters regarding human resource management in the County 
                        and operationalize Sections 59 (1) (a), (b), (g), (h), (i) of the County Governments Act, 2012. 
                    </p>
                    <p>The Committee comprises of three (3) members of the Board. The board committee secretaries shall be appointed 
                        by the board from the secretariat.  
                    </p>
                    <p>The quorum necessary for the transaction of business shall be two (2) members of the Board.</p>
                    <p>At the beginning of a financial year, the Committee will draw a timetable of its meetings for that particular 
                        year.  The Committee may however meet at such times as the business of the Committee shall require.
                    </p>
                    <p><b>The Committee’s main responsibilities and functions shall be to:</b></p>
                        <ol>
                            <li>Facilitate the development of coherent Integrated Human Resource Planning and budgeting for 
                                personnel emoluments in the County.
                            </li>
                            <li>Advise the County Government on Human Resource Management and Development.</li>
                            <li>Prepare a Recruitment and Selection Policy.</li>
                            <li>Scrutinize the indents for positions to be advertised.</li>
                            <li>Analyze reports and proposals on the establishment and abolition of offices. </li>
                            <li>Analyze and make recommendations on requests for appointments and promotions.</li>
                            <li>Prepare criteria for ensuring that all appointments are in accordance with Articles 10, 
                                27(4) &(8), 56 (c), and 232 (i) of the CoK, as indicated in Section 65 of CGA.
                            </li>
                        </ol>
                </div>
            <h3 className="ribbon">Discipline Committee</h3>
                <div className='about-content'>
                    <p>The objective of the Committee is to consider all matters regarding disciplinary control in the County 
                        and operationalize Sections 59 (1) (c) of the County Governments Act, 2012. 
                    </p>
                    <p>The Committee comprises of three (3) members of the Board. The board committee secretaries shall be a
                        ppointed by the board from the secretariat.
                    </p>
                    <p>The quorum necessary for the transaction of business shall be two (2) members of the Board.</p>
                    <p>At the beginning of a financial year, the Committee will draw a timetable of its meetings for that 
                        particular year.  The Committee may however meet at such times as the business of the 
                        Committee shall require.
                    </p>
                    <p><b>The Committee’s main responsibilities and functions shall be to:</b></p>
                        <ol>
                            <li>Address issues related to discipline in the County Government.</li>
                            <li>Develop a system of handling discipline cases and appeals to Public Service Commission.</li>
                            <li>Prepare guidelines for carrying out investigations for disciplinary cases.</li>
                            <li>Analyze investigations, monitoring and evaluation reports and make appropriate 
                                recommendations to facilitate decision making.
                            </li>
                            <li>Develop and manage the county disciplinary policy.</li>
                            <li>Respond to Audit queries related to discipline.</li>
                        </ol>  
                </div>
            <h3 className="ribbon">Inter-governmental Relations and ICT Committee</h3>
                <div className='about-content'>
                    <p>The objective of the Committee is to consider all matters regarding the Board’s stakeholders 
                        in the County, ICT and automation of the Board’s activities and operationalize Sections 59 (1) (j) 
                        of the County Governments Act, 2012. 
                    </p>
                    <p>The Committee comprises of three (3) members of the Board.  The board committee secretaries 
                        shall be appointed by the board from the secretariat. 
                    </p>
                    <p>The quorum necessary for the transaction of business shall be two (2) members of the Board.</p>
                    <p>At the beginning of a financial year, the Committee will draw a timetable of its meetings for that 
                        particular year.   The Committee may however meet at such times as the business of the Committee shall require.
                    </p>
                    <p><b>The Committee’s main responsibilities and functions shall be to:</b></p>
                        <ol>
                            <li>Engage trade unions, Ministry of Labor on Terms and Conditions of Service for County officers.</li>
                            <li>Make recommendations to the Salaries and Remuneration Commission.</li>
                            <li>Make proposals on the use of ICT – automating operations and activities of the Board.</li>
                            <li>Provide a mechanism for harmonizing all officers inherited from the former Local Authority, those 
                                seconded/devolved from the National Government, and those appointed by NCPSB in consultation with SRC.
                            </li>
                            <li>Enhance the transition process by liaising with the intergovernmental Technical Relations Committee 
                                and the Ministry of Devolution and Planning.
                            </li>
                            <li>Enhance collaboration with stakeholders such as the Council of Governors, CPSB National Consultative 
                                Forum, Nairobi City County Assembly etc.
                            </li>
                            <li>Develop policies on donor funded and joint venture staff for implementation by the County. </li>
                            <li>Propose modalities of handling officers who may not be absorbed in the new Nairobi City County 
                                Public Service structure
                            </li>
                        </ol>
                </div>  
            <h2>Governance Committee</h2>
            <h3 className="ribbon">Roles and mandate of the committee</h3>
                <div className='about-content'>
                    <p>The objective of the Committee is to consider all matters regarding values and principles activities of 
                        the board and operationalize Sections 59 (1) (j) of the County Governments Act, 2012. The Committee 
                        comprises of three (3) members of the Board.
                    </p>
                    <p>The quorum necessary for the transaction of business shall be two (2) members of the Board.</p>
                    <p>At the beginning of a financial year, the Committee will draw a timetable of its meetings for that 
                        particular year.   The Committee may however meet at such times as the business of the Committee shall require.
                    </p>
                    <p><b>The Committee’s main responsibilities and functions shall be to:</b></p>
                        <ol>
                            <li>Prepare annual report for adoption by the Board and presentation to the County Assembly pursuant 
                                to section 59(1) and (5) of the County Governments Act, 2012 on the extent to which the County 
                                Public Service Board complies with the values, principles and discipline referred to in Articles 
                                10 and 232 of the Constitution of Kenya. 
                            </li>
                            <li>Propose activities for promotion of the Values and Principles Articles 10 and 232 of the 
                                Constitution of Kenya.
                            </li>
                            <li>Propose modalities for monitoring the extent to which the Values and Principles are complied 
                                with in the County Public Service.
                            </li>
                            <li>Make proposals for the improvement in the discharge of values and principles in public service.</li>
                            <li>Propose ways of sensitization and awareness creation on values and principles of governance in 
                                the County public service.
                            </li>
                        </ol>
                </div>

        </div>
    </div>
  )
}

export default BoardCommitties