import axios from './../libs/axios';

class JobOpeningService {
    constructor() {
        this.contentType = 'application/search.criteria.v1+json'
    }
    
    jobs() {
        return axios.post(`job/openings/list/published`, {
            "paging": {
                "pageNumber": 1,
                "pageSize": 100
            }
        }, {
            headers: {
                'Content-Type': this.contentType
            }
        });
    }
    getJob(id) {
        return axios.get(`job/openings/${id}`);
    }
}

export default new JobOpeningService();