import React, {useState, useEffect} from 'react';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import {onError} from '../../libs/errorLib';
import NewsService from '../../services/news.service';
import * as AiIcons from "react-icons/ai";
import moment from 'moment';
import parser from "html-react-parser";


const News = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [selectedId, setSelectedId] = useState();

    const handleClick = (id) => {
        setSelectedId(id !== selectedId ? id : null);
    };

    useEffect(() => {
        getNotices()
    }, []);
    const getNotices = async () => {
        setIsLoading(true);
        try {
            const response = await NewsService.getList()
            setDataSource(response.results);
        } catch (e) {
            onError(e)
        } finally {
            setIsLoading(false)
        }

    }
    return (
        <>
            <div className="listing-main">
                <div className="list-main-card">
                    <h3 className="ribbon">New(s)</h3>
                    {
                        isLoading ? <LoadingSpinner/> :
                            <div className="listing-list">
                                <ol>
                                    {
                                        dataSource ?
                                            dataSource.map(data => {
                                                return (
                                                    <li>
                                                        <div className='listing-list-item news-listing'>
                                                            {data.title}

                                                            <button
                                                                className='primary_btn'
                                                                onClick={() => handleClick(data.id)}>
                                                                <AiIcons.AiFillCaretDown/>
                                                            </button>
                                                        </div>
                                                        {selectedId === data.id && (
                                                            <div className='select-option'>
                                                                <h5>Created on {moment(data?.created).format("dddd, DD MMMM YYYY hh:mm a")}</h5>
                                                                <h5>{data?.status}</h5>
                                                                <p>{parser(`${data?.content}`)}</p>
                                                            </div>
                                                        )}
                                                    </li>
                                                )
                                            })
                                            : 'No Data'
                                    }

                                </ol>

                            </div>
                    }

                </div>
            </div>
        </>
    )
}

export default News