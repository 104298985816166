import axios from './../libs/axios';

class NewsService {
    
    getList(){
        return axios.get(`/news`, {});
    }
    
    getSingle(id){
        return axios.get(`/news/${id}`);
    }
}

export default new NewsService();