import { createSlice } from "@reduxjs/toolkit";

// ============ Create ENUMS mechanism ============
export const STATUSES = Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
});

const initialState = {
    isLoggedIn: false,
    id: null,
    firstName: null,
    lastName: null,
    middleName: null,
    mobileNumber: null,
    nationalId: null,
    email: null,
    access_token: null,
    refresh_token: null,
    status: STATUSES.IDLE,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setLoginUser(state, action) {
            const { access_token, refresh_token } = action.payload;
            state.isLoggedIn = true;
            state.access_token = access_token;
            state.refresh_token = refresh_token;
        },
        setUser(state, action) {
            const { id, firstName, lastName, middleName, mobileNumber, email, nationalId } = action.payload;
            state.isLoggedIn = true;
            state.id = id;
            state.firstName = firstName;
            state.middleName = middleName;
            state.lastName = lastName;
            state.email = email;
            state.mobileNumber = mobileNumber;
            state.nationalId = nationalId;
        },
        removeLoginUser(state, action) {
            state.isLoggedIn = false;
            state.id = null;
            state.firstName = null;
            state.lastName = null;
            state.middleName = null;
            state.email = null;
            state.access_token = null;
            state.refresh_token = null;
        },
    },
});

export const { setLoginUser, removeLoginUser, setUser } = authSlice.actions;
export default authSlice.reducer;
