import React,{useState} from 'react';
import * as BsIcons from "react-icons/bs";
import { FAQdata } from './FAQ-data';

const FAQ = () => {
    const [selectedId, setSelectedId] = useState();
    const handleClick = (id) => {
        setSelectedId(id !== selectedId ? id : null);
    };
  return (
    <>
        <div className="listing-main">
            <div className="list-main-card">
                <h3 className="ribbon">Question/Answers (FAQ)</h3>
                {FAQdata.length && FAQdata.map((item,index)=>
                    <div className="listing-list">
                        <div className='faq-question' onClick={() => handleClick(item.id)}>
                            <h3>
                                <span><BsIcons.BsFillQuestionSquareFill/></span>
                                {item.question}
                            </h3>
                            <span>
                                {selectedId === item.id ?
                                    <BsIcons.BsFillCaretUpFill/> : <BsIcons.BsFillCaretDownFill/>
                                }
                            </span>
                        </div>
                        {selectedId === item.id && (
                            <div className='faq-answer'>
                                {item.answer} 
                            </div>           
                        )}
                    </div>
                )}
            </div>
        </div>
    </>
  )
}

export default FAQ