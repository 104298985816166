import React, {useState} from 'react';
import "./ContactUs.css";
import {Form, Input} from "antd";
import supportTicketService from '../../services/supportTicket.service';
import { onError } from '../../libs/errorLib';
import { onSucess } from '../../libs/onSuccess';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';

const ContactUs = () => {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);

    const onSubmit = async (values) => {
        
        setLoading(true);

        try {
            const supportTicket = {
                "firstName": values.firstName,
                "lastName": values.lastName,
                "nationalId": values.nationalId,
                "phoneNumber": values.phoneNumber,
                "email": values.email,
                "message": values.message,
                "address": values.address
                };

            const response = await supportTicketService.submit(supportTicket);

            onSucess("Message submitted successfully!.");
           
        } catch (e) {
            onError(e)
        } finally {
            // props.handleCancel()
            setLoading(false)
        }
    };
     
  return (
    <>
        <div className="contact-us-main">
            <div className='contact-us'>
                <h2 className='theme-h2'>Contact Details</h2>
                <div className="contact-detail">
                    <div className='contact-detail-item'>
                        <h3>Nairobi City County Public Service Board</h3>
                        <p>2nd Floor City Hall, P.O. Box 30075-00100, Nairobi, Kenya.</p>
                    </div>
                    <div className='contact-detail-item'>
                        <h3>Telephone Contacts</h3>
                        <p>020 2177325</p>
                    </div>
                    <div className='contact-detail-item'>
                        <h3>Email</h3>
                        <p>cpsb@nairobi.go.ke</p>
                    </div>
                </div>
                {/* <h2  className='theme-h2'>Map Location</h2>
                <div className="map">
                    <iframe id="map-canvas" class="map_part" width="100%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                </div> */}
                <div className="contact-us-form">
                    <Form form={form} onFinish={onSubmit}>
                        <div className='merge-fields'>
                            <Form.Item  className="input-50" 
                            label="First Name" name="firstName" rules={[
                                {
                                  required: true,
                                },
                              ]}>
                                    <Input type="text" placeholder="First Name" />
                            </Form.Item>


                            <Form.Item className="input-50" name="lastName" 
                            label="Last Name" rules={[
                                {
                                  required: true,
                                },
                              ]}>
                                <Input type="text" placeholder="last Name" />
                            </Form.Item>
                        </div>
                        <div className='merge-fields'>
                            <Form.Item  className="input-50" name="nationalId"
                            label="National Identity Number">
                                <Input type="numbers" placeholder="National Identity Number" />
                            </Form.Item>
                            <Form.Item  className="input-50" name="phoneNumber"
                            label="Phone Number" rules={[
                                {
                                  required: true,
                                },
                              ]}>
                                <Input type="numbers" placeholder="Phone Number" />
                            </Form.Item>
                            </div>
                        <Form.Item  name="address"
                        label="Address" rules={[
                            {
                              required: true,
                            },
                          ]}>
                            <Input type="text" placeholder="Address" />
                        </Form.Item>
                        <Form.Item name="email"
                        label="Email">
                            <Input type="email" placeholder="Email" name="email"rules={[
                  {
                    required: true,
                  },
                ]}/>
                        </Form.Item>
                        <Form.Item
                        label="Message" name="message" rules={[
                            {
                              required: true,
                            },
                          ]}>
                            <Input.TextArea type="text" placeholder="Message" />
                        </Form.Item>
                        <Form.Item style={{textAlign:"center", marginTop:"20px"}}>
                            {/* <button className='primary_btn'>Submit</button> */}



            <button loading={loading} className="primary_btn" htmlType="button">
                {loading ? <LoadingSpinner /> : "Submit"}
            </button>



                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    </>
    )
}

export default ContactUs