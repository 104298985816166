import React from 'react';
import "./About.css";

const About = () => {
  return (
    <div className="about-main-page">
      {/* -----------------about old start */}
      <div className='about-main'>
        <h3 className="ribbon">VISION</h3>
          <p className='about-content'>The City of Choice to invest, work and live in.</p>
        <h3 className="ribbon">MISSION</h3>
          <p className='about-content'>To provide affordable, accessible and sustainable quality services, enhancing community participation and creating a secure climate for political,social and economic development through the commitment of a motivated and dedicated team.</p>          
        <h3 className="ribbon">CORE VALUES</h3>
          <div className='core-values-list about-content'>
            <a href="">Accountability</a>
            <a href="">Transparency</a>
            <a href="">Excellence</a>
            <a href="">Integrity</a>
            <a href="">Responsiveness</a>
            <a href="">Equity</a>
            <a href="">Team work</a>
          </div>
        <h3 className="ribbon">FUNCTIONS OF CPSB</h3>
          <div className='about-content'>
            <p>As per County Govt Act 2012 Sec 59</p>
              <ol>
                <li>Establish and abolish offices in the county public service.</li>
                <li>Appoint persons to hold and to act in offices in the county public service including confirming appointments.</li>
                <li>Exercise disciplinary control over staff in the county public service.</li>
                <li>Prepare regular reports for submission to the county assembly on the execution of its functions.</li>
                <li>Promote ethical standards in the 0perations of the county public service.</li>
                <li>Develop a coherent ,intergrated HR planning and budgeting for personnel emoluments in the county.</li>
                <li>Advise on the implementation and monitoring of performance management system.</li>
                <li>Make recommendations to SRC.</li>
              </ol>  
          </div>
        <h3 className="ribbon">POWERS OF CPSB</h3>
          <ol className='about-content'>
            <li>Inform and educate county public officers and the public about the values and principles</li>
            <li>Recommend to the county government effective measures to promote the values and principles.</li>
            <li>Assist county government in the formulation and implementation of programmes intended to inculcate in public officers the duty to uphold the values and principles.</li>
            <li>Advise the county governments on their obligations under international treaties and conventions on good governance in the county public service.</li>
            <li>Visit any county public office or body with a view to assessing and inspecting the status of compliance with the values and principles.</li>
            <li>Visit any county public office or body with a view to assessing and inspecting the status of compliance with the values and principles.</li>
            <li>Investigate, on its own initiative or upon a complaint made by any person or group of persons, the violation of any values and principles.</li>
            <li>Recommend to the relevant lawful authority, any necessary action in view of the violation of the values and principles by any person or public body.</li>
            <li>Cooperate with other institutions working in the field of good governance in the public service.</li>
            <li>Perform any other functions as the Board considers necessary for the promotion of the values and principles.</li>    
          </ol>  
      </div>
      {/* -----------------about old end */}
    </div>
  )
}

export default About