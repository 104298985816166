import axios from './../libs/axios';

class JobApplicationService {
    constructor() {
        this.contentType='application/job.application.create.v1+json'
    }
    submitApplication(payload){
        return axios.post(`job/applications`, payload, {
            headers: {
                'Content-Type':this.contentType
            }
        });
    }
    getList(){
        return axios.post(`/job/applications/current`, {});
    }
}

export default new JobApplicationService();