const link=<a href="http://cpsb.nairobi.go.ke">http://cpsb.nairobi.go.ke</a>
export const FAQdata=[
    {
        id:1,
        question:"How do I obtain a list of job openings at Nairobi City County?",
        answer:`Vacancies are published on the Nairobi City County Recruitment Portal.
        The portal can be found by clicking on the jobs link from the main Nairobi City County website.`,
    },
    {
        id: 2,
        question: "How do I apply for a job at Nairobi City County?",
        answer: 'Access the Recruitment portal on http://cpsb.nairobi.go.ke to apply for NCC vacancies.',
    },
    {
        id:3,
        question:"Do I need to upload/attach any document during the application process?",
        answer:<>
            {`Yes. You will need soft copies/scanned documents of the following;`}<br/>
            {`National ID, academic qualification certificates, professional qualification certificates and CV.`}
        </>
    },
    {
        id:4,
        question:"Why do i register before i start applying for a job?",
        answer:`Registering will enable you to login and continue the application process if there was a disruption e.g if power goes off.`
    },
    {
        id:5,
        question:"I’m having issues applying online, how do I get help?",
        answer:`Fill up the help request form and submit. You will get feedback on email.`
    },
    {
        id:6,
        question:"Can I copy and paste information from another document into the online application?",
        answer:`Yes, Your details can be copied from your soft copy documents and pasted during the online application process.`
    },
    {
        id:7,
        question:"What are the hours that I can submit my application?",
        answer:`The Recruitment Portal is available 24hrs a day hence you can submit your application any time before the set deadline.`
    },
    {
        id:8,
        question:"What documents do I need to submit to apply for a current job opening?",
        answer:`You need to attach your CV,Academic Testimonials and any other document requested during the application process.`
    },
    {
        id:9,
        question:"How do I upload / attach my CV and other documents?",
        answer:`Click on the Browse button, Select the document from your computer and click attach.`
    },
    {
        id:10,
        question:"Will I hear from the Nairobi City County Public Service Board after I apply?",
        answer:`You will receive an email confirmation once you submit your application.`
    },
    {
        id:11,
        question:"Will I be required to bring original documents if I’m shortlisted for an interview?",
        answer:`Yes, You will be required to bring your original documents if you are shortlisted for an interview.`
    },
    {
        id:12,
        question:"Why didn't i receive an SMS confirmation after i submitted the job application?",
        answer:`If you have opted out of receiving promotional messages, you wont receive an SMS, 
                to activate dial *456*9*5# for Safaricom , *100# for Airtel.`
    }
]