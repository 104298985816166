import React from 'react';
import "./About.css";

const BoardMandate = () => {
  return (
    <div className="about-main-page">
        <h1>Board's Mandate</h1>
        <div className='about-main'>
        <h3 className="ribbon">Introduction</h3>
          <p className='about-content'>The Board Charter is considered best practice for any Board to enhance Board efficiency 
            and effectiveness and promote good corporate governance.  It is also necessary to clarify and ease the interface 
            between the Board and the County Management.  Though the main duties of the Nairobi City County Public Service Board 
            are spelt out in the County Governments Act 2012, the Act does not spell out the operational procedures for the Board. 
            This Charter therefore, inter alia, provides the procedures to be followed by the Board in its operations.
          </p>
          <p className='about-content'>
            The Nairobi City County Public Service Board, is a body corporate capable of suing and being sued.
          </p>
      </div>

      <div className='about-main'>
        <h2>THE BOARD</h2>
        <h3 className="ribbon">Composition, Mix and Size of the Board</h3>
          <div className='about-content'>
            <p><b>The Board consists of:</b></p>
            <ol>
              <li>The Chairperson</li>
              <li>Three to Five other members</li>
              <li>The Secretary to the board</li>
            </ol>
            <p>The Composition of the Board is stipulated under Section 58 of the County Governments Act 2012 (“the Act”). The 
              Board shall be facilitated by a Board secretariat to be headed by the Secretary who will also be the CEO of the 
              secretariat.</p>
          </div>
        <h3 className="ribbon">Appointment to the Board</h3>
          <p className='about-content'>The Governor nominates the Board members and appoints them with approval of the County 
            Assembly. The appointment of the members of the Board are through a competitive process.
          </p>
        <h3 className="ribbon">Statutory Functions and Powers of the Nairobi City County Public Service Board</h3>
          <div className='about-content'>
            <p><b>The Board’s main functions and powers under section 59(1) of the Act are to:</b></p>
            <ol>  
              <li>Establish and abolish offices in the County Public Service.</li>
              <li>Appoint persons to hold or act in offices of the County Public Service including the Boards of Cities and 
                Urban areas within the County and to confirm appointments.
              </li>
              <li>Exercise disciplinary control over, and remove, persons holding or acting in those offices.</li>
              <li>Prepare regular reports for submission to the County Assembly on the Execution of the functions of the Board.</li>
              <li>Promote in the County Public Service the Values and Principles referred to in Articles 10 and 232 of the 
                Constitution.
              </li>
              <li>Evaluate and report to the County Assembly on the extent to which the values and principles referred to in 
                Articles 10 and 232 are complied with in the County Public Service.
              </li>
              <li>Facilitate the development of coherent, integrated human resource planning and budgeting for personnel 
                emoluments in the county.
              </li>
              <li>Advise the County Government on human resource management and development.</li>
              <li>Advise county government on implementation and monitoring of the national performance management 
                system in counties.
              </li>
              <li>Make recommendations to the Salaries and Remuneration Commission on the remuneration, penton and 
                gratuities for County Public Service employees.
              </li>
            </ol>
          </div>
        <h3 className="ribbon">Functions of the Board as a Collective Body</h3>
          <ol className='about-content'>
            <li>The Board will define its strategic intent, objectives, and its values.  Both the objectives and values 
              should be clear, concise and achievable.  It shall also ensure that procedures and practices are in place, 
              to protect the Board’s assets and reputation.
            </li>
            <li>In the discharge of its obligations, the Board assume the following responsibilities:
              <ol>
                <li>Monitor implementation of its plans and strategies;</li>
                <li>Ensure ethical behaviors and compliance with relevant laws and regulations, audit and accounting 
                  principles and corporate policies and procedures;
                </li>
                <li>Define levels of materiality, reserve specific powers to itself, delegate other matters through 
                  written authority and institute effective mechanisms that monitor the performance of delegated matters;
                </li>
                <li>Receive any grants, gifts, donations or endowments and make legitimate disbursements therefrom;</li>
                <li>Open bank accounts for the Board’s funds;</li>
                <li>Act responsibly towards its stakeholders; and</li>
                <li>Be aware of, and committed to, the principles of good governance.</li>
              </ol>
            </li>
            <li>The Board is concerned with key elements of the governance processes which will sustain its operation 
              and that of the County with particular attention to the following:
              <ol>
                <li>Setting and reviewing processes and compliance with key regulatory and legal issues including an 
                  enterprise risk management framework;
                </li>
                <li>Delegating authority for capital expenditure;</li>
                <li>Selecting, monitoring and evaluating the performance of the staff of the Board and generally reviewing 
                  succession planning for the Board;
                </li>
                <li>Providing oversight of performance against targets and objectives; and</li>
                <li>Overseeing the reporting to stakeholders on the direction, governance, and performance of the County as 
                  per its statutory mandates.
                </li>
              </ol>
            </li>
          </ol>
      </div>

      <div className='about-main'>
        <h3 className="ribbon">The Chairperson</h3>
          <div className='about-content'>
            <p><b>The Chairperson will:</b></p>
            <ol>
              <li>Chair and conduct all meetings of the Board;</li>
              <li>Provide overall leadership in areas of policy development and direction;</li>
              <li>Act as the principal spokesperson of the Board;</li>
              <li>Maintain a harmonious working relationship between the Board and the County Leadership;</li>
              <li>Provide a public relations role between the Board and its stakeholders;</li>
              <li>Harness the collective skills of the Board and its Committees, and those of other employees of the Board for attainment of the Board’s mandate as per the Act and also performance targets as may be agreed;</li>
              <li>Request the appointing authority to appoint Board members in the event of a vacancy;</li>
              <li>Encouraging Board members to participate fully in the deliberations of the Board and its Committees;</li>
              <li>Ensure that strategies exist for constant monitoring and evaluation of the effectiveness of the Board, individual members and the Secretariat staff;</li>
              <li>Participate in setting of the agenda for both Board and Committee meetings;</li>
              <li>Sign the confirmed minutes of Board meetings;</li>
              <li>Monitor the implementation of Board decisions;</li>
              <li>Ensure that new members of the Board are properly inducted and adequate training programmes exist to keep them abreast of developments in corporate governance;</li>
              <li>Ensure that the content and order of the agenda are appropriate and that, through the secretary, members of the Board receive the relevant papers in good time;</li>
              <li>Ensure that members of the Board are properly briefed on issues arising at Board meetings and that all available information on an issue is before the members;</li>
              <li>Maintain dialogue with the secretary over all operational matters and consult promptly with the other members over any matter that gives cause for major concern;</li>
              <li>Ensure that the Board sets its strategic direction and receives and approves the Board’s strategic plans and annual operating budgets;</li>
              <li>Ensure that the Board approves its Board structures for the attainment of the Board’s mandate;</li>
              <li>Ensure that the Board monitors and evaluates corporate governance; and</li>
              <li>Ensure that the Board observes the channels of communication between Nairobi City County Public Service Board, County, and National Government, and other stakeholders.</li>
            </ol>
          </div>
        <h3 className='ribbon'>The Vice-Chairperson</h3>
          <p className='about-content'>The Vice-Chairperson will deputize the performance of the functions of the Chairperson 
            as listed in (1) to (20) above.
          </p>
          <p className='about-content'>The Chairperson and Vice-Chairperson shall be of the opposite gender.</p>
        <h3 className='ribbon'>
          The Chief Executive Officer (CEO) and Secretary to the Nairobi City County Public Service Board
        </h3>
          <p className='about-content'>
            The Secretary, who is appointed under Section 58(1)(c) of the Act, shall exercise both CEO and Secretarial functions.
          </p>

        <h3 className='ribbon'>Chief Executive Officer’s Functions</h3>
          <div className='about-content'>
            <p><b>As the Head of the Board Secretariat, the CEO shall:</b></p>
            <ol>
              <li>Implement the Board’s Decisions and Resolutions;</li>
              <li>Oversee the day-to-day business of the Board’s Secretariat;</li>
              <li>Implement Board’s development strategy, business plans, approved annual operating budgets, and 
                established internal monitoring and control systems and procedures;
              </li>
              <li>Ensure that effective communication exists between the Secretariat and the Board;</li>
              <li>Provide leadership to the employees of the Secretariat;</li>
              <li>Oversee and ensure implementation of corporate policies and programs;</li>
              <li>Foster a corporate culture that promotes ethical practices and good corporate citizenship;</li>
              <li>Advise the board on statutory compliance;</li>
              <li>Carry out any other responsibilities necessary as delegated by the board.</li>
            </ol>
          </div>
      </div>
    </div>
  )
}

export default BoardMandate