import React from 'react';
import "./About.css";

const ServiceCharter = () => {
  return (
    <div className="about-main-page">
      <h1>Service Charter</h1>
      <div className="about-main">
        <h3 className="ribbon">FORWARD</h3>
        <p className="about-content">
          The Nairobi City County Public Service Board Charter sets out the
          philosophy of the Board as it conducts its operations.
        </p>
        <p className="about-content">
          Faithful discharge of the mandate of the Board requires persons of
          high integrity, commitment, dedication and foresight both at the Board
          and County levels.
        </p>
        <p className="about-content">
          The Board members have pledged to dedicate themselves to the service
          of the Nairobi City County Public Service Board. In particular, they
          shall uphold the tenets of good corporate governance. They shall be
          accountable, efficient and effective, responsible and transparent, be
          persons of integrity and exercise fairness in all their dealings.
        </p>
        <p className="about-content">
          This Board Charter is a commitment by the members of the Board to
          discharge the mandate of the Nairobi City County Public Service Board
          and outlines the rules that shall guide them.
        </p>
        <p className="about-content">
          It is hoped that this Charter shall be a useful tool in enabling and
          ensuring good governance in the Nairobi City County Public Service
          Board. This Charter however does not in any way purport to replace or
          supersede any laws and regulations that guide the Board.
        </p>
        <p></p>
        <p></p>
        <p>
          <b>
            <em>
              Hon. Thomas Mweu Kasoa<br></br>
              Chairperson<br></br>
              Nairobi City County Public Service Board
            </em>
          </b>
        </p>
      </div>
      <div className="about-main">
        <h3 className="ribbon">VISION</h3>
        <p className="about-content">
          A professional and Sustainable Public Service.
        </p>
        <h3 className="ribbon">MISSION</h3>
        <p className="about-content">
          To attract, enable, retain and inspire a professional and result
          oriented Public Service
        </p>
        <h3 className="ribbon">CORE VALUES</h3>
        <ol>
          <li href="">Integrity</li>
          <li href="">Inclusivity</li>
          <li href="">Professionalism</li>
          <li href="">Responsiveness</li>
          <li href="">Transparency & Accountability</li>
          <li href="">Fairness and Equity</li>
          <li href="">Efficiency</li>
        </ol>
      </div>
      <div className="about-main">
        <div className="about-content">
          <table>
            <tr>
              <th>No.</th>
              <th>Services</th>
              <th>Requirements to obtain services</th>
              <th>Cost</th>
              <th>Timeline</th>
            </tr>
            <tr>
              <td>01</td>
              <td>Respond to external correspondences/enquiries</td>
              <td>
                Duly filled enquiry form, letters, memorandum, emails, visits,
                telephone calls
              </td>
              <td>Nill</td>
              <td>Within 48 hours</td>
            </tr>
            <tr>
              <td>02</td>
              <td>Abolish and create offices</td>
              <td>Request from the relevant Sector to the board</td>
              <td>Nill</td>
              <td>Within 90 days</td>
            </tr>
            <tr>
              <td>03</td>
              <td>Confirm and Appoint persons to hold or act in offices</td>
              <td>Request from relevant sector</td>
              <td>Nill</td>
              <td>Within 90 days</td>
            </tr>
            <tr>
              <td>04</td>
              <td>Exercise Disciplinary control over county Staff</td>
              <td>Disciplinary report</td>
              <td>Nill</td>
              <td>Within 90 days</td>
            </tr>
            <tr>
              <td>05</td>
              <td>Prepare regular reports to the county assembly</td>
              <td>Statutory</td>
              <td>Nill</td>
              <td>Anually</td>
            </tr>
            <tr>
              <td>06</td>
              <td>Promote Ethical Standards in the County Public Service</td>
              <td>Initiative from the relevant board committee</td>
              <td>Nill</td>
              <td>Bi-anually</td>
            </tr>
            <tr>
              <td>07</td>
              <td>
                Advise on the implementation and monitoring of performance
                management system
              </td>
              <td>Reports from public service management</td>
              <td>Nill</td>
              <td>Anually</td>
            </tr>
            <tr>
              <td>08</td>
              <td>Make recommendations to SRC</td>
              <td>Initiative of the Board</td>
              <td>Nill</td>
              <td>Anually</td>
            </tr>
            <tr>
              <td>09</td>
              <td>Respond to board employee welfare</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>10</td>
              <td>Liaison with strategic partners</td>
              <td>Letters, emails, visits</td>
              <td>Nill</td>
              <td>Within 1 week</td>
            </tr>
            <caption>
              <i>
                Any service that does not conform to the above standards should
                be reported to the of the Secretary/CEO City Hall Second Floor{" "}
                <b>P.O. Box:</b>30075-00100, Nairobi, Kenya. ,<b> Tel:</b>
                020-2177325 , <b> Email:</b>cpsb@nairobi.go.ke ,{" "}
                <b> Website:</b>
                <a href="http://cpsb.nairobi.go.ke" target="_blank">
                  cpsb.nairobi.go.ke
                </a>{" "}
              </i>
            </caption>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ServiceCharter