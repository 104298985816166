import React from 'react';
import "./Footer.css";
import { MenuData } from '../Navbar/MenuData';
import { Link } from 'react-router-dom';
import * as AIicons from "react-icons/ai";
import { TwitterTimelineEmbed } from 'react-twitter-embed';


const Footer = () => {

  const today = new Date();
  const year = today.getFullYear();

  return (
    <div className="footer-main">
      <div className="footer">
        {/* <div className='footer-section footer-about'>
                <div className='footer-content'>
                    <h2>About us</h2>
                    <p>
                        The Nairobi City County is charged with the responsibility of providing a variety of services to 
                        residents within its area of jurisdiction. These include the services that were hitherto provided 
                        by the defunct City Council and the ones that have been transferred from the national government.
                    </p>
                </div>
            </div> */}

        {/* <div className='footer-section'>
                <div className='footer-content'>
                    <h2>Contact Details</h2>
                    <div class="textwidget">
                        <div className='footer-contact-detail'>
                            <h3>Nairobi City County</h3>
                            <p>Nairobi City Hall,</p> 
                            <p>City Hall way, P.O Box 30075-00100</p>
                            <p>Nairobi, Kenya</p>
                        </div>
                        <div className='footer-contact-detail'>
                            <h3>Telephone Contacts</h3>
                            <p>+254 725 624 489</p>
                            <p>+254 738 041 292</p>
                        </div>
                        <div className='footer-contact-detail'>
                            <h3>E-mail Address</h3>
                            <p>info@nairobi.go.ke</p>
                        </div>
                        <div className='footer-contact-detail'>
                            <h3>Website:</h3>
                            <p>www.nairobi.go.ke</p>
                        </div>
                    </div>
                </div>
            </div> */}

        <div className="footer-section">
          <div className="footer-content">
            <h2>Quick Links</h2>
            <div className="footer-links">
              {/* {MenuData?.map((item)=>{
                            return(
                                <>
                                    <h4>
                                        <AIicons.AiOutlineDoubleRight className='footer-icon'/> 
                                        <Link to={item.path} key={item.key}> {item.title}</Link>
                                    </h4>
                                </>
                            )
                        })} */}
              <h4>
                <AIicons.AiOutlineDoubleRight className="footer-icon" />
                <Link to="/"> Home</Link>
              </h4>
              <h4>
                <AIicons.AiOutlineDoubleRight className="footer-icon" />
                <Link to="vacancies"> Vacancies</Link>
              </h4>
              <h4>
                <AIicons.AiOutlineDoubleRight className="footer-icon" />
                <Link to="notices"> Notices</Link>
              </h4>
              <h4>
                <AIicons.AiOutlineDoubleRight className="footer-icon" />
                <Link to="contact-us"> Contact Us</Link>
              </h4>
              <h4>
                <AIicons.AiOutlineDoubleRight className="footer-icon" />
                <Link to="frequently-asked-questions"> FAQ</Link>
              </h4>
            </div>
          </div>
          {/* <div className='footer-content footer-twitter'>
                    <h2>Latest Tweet</h2>
                    <TwitterTimelineEmbed
                        sourceType="tweet"
                        screenName="047County"
                        options={{height: 300}}
                    />                        
                </div> */}
        </div>
        <div className="footer-section">
          <div className="footer-content">
            <h2>Useful Links</h2>
            <div className="footer-links">
              <h4>
                <AIicons.AiOutlineDoubleRight className="footer-icon" />
                <a href="https://nairobi.go.ke" target="_blank">
                  {" "}
                  Nairobi City County
                </a>
              </h4>
              <h4>
                <AIicons.AiOutlineDoubleRight className="footer-icon" />
                <a href="https://nairobiassembly.go.ke" target="_blank">
                  {" "}
                  Nairobi City County Assembly
                </a>
              </h4>
              <h4>
                <AIicons.AiOutlineDoubleRight className="footer-icon" />
                <a href="https://www.publicservice.go.ke" target="_blank">
                  {" "}
                  Public Service Commission
                </a>
              </h4>
              {/*<h4>*/}
              {/*    <AIicons.AiOutlineDoubleRight className='footer-icon'/> */}
              {/*    <a href='https://www.ecitizen.go.ke' target="_blank"> e-Citizen Portal</a>*/}
              {/*</h4>*/}
              <h4>
                <AIicons.AiOutlineDoubleRight className="footer-icon" />
                <a href="https://src.go.ke/" target="_blank">
                  {" "}
                  Salaries & Remuneration Commission
                </a>
              </h4>
            </div>
          </div>
        </div>
        <div className="footer-section">
          <div className="footer-content">
            <h2>News Letter</h2>
          </div>
          <div className="news-letter">
            <input placeholder="Write your email" type="email" />
            <button className="secondary_btn">Subscribe</button>
          </div>
        </div>
      </div>
      <div className="copy-right">
        {/* <p>    
                Copyright © Nairobi City County. All Rights Reserved. Crafted by <a href='https://codex-technologies.com/' target="_blank">Codex Technologies</a>
            </p> */}
        <p>
          © { year } Nairobi City County Public Service Board | 020 2177325 |
          cpsb@nairobi.go.ke |{" "}
          <a href="https://cpsb.nairobi.go.ke" target="_blank">
            cpsb.nairobi.go.ke
          </a>{" "}
          | City Hall, P.O. Box 30075-00100, Nairobi, Kenya.
        </p>
      </div>
    </div>
  );
}

export default Footer