import React from "react";

const JobFormOptions=[
    // --------------------Personal Details Options----------
    // salutation options index=0
    {
        salutationOptions:[
            {
                value: 'MR',
                label: 'Mr.',
            },
            {
                value: 'MRS',
                label: 'Mrs.',
            },
            {
                value: 'MISS',
                label: 'Miss.',
            },
            {
                value: 'DR',
                label: 'Dr.',
            },
            {
                value: 'REV',
                label: 'Rev.',
            },
            {
                value: 'PROF',
                label: 'Prof.',
            },
            {
                value: 'ENG',
                label: 'Eng.',
            },
            {
                value: 'OTHER',
                label: 'Other',
            },
        ]
    },
    // gender options index=1
    {
        genderOptions:[
            {
                value: 'MALE',
                label: 'Male',
            },
            {
                value: 'FEMALE',
                label: 'Female',
            },
            {
                value: 'OTHER',
                label: 'Other',
            },
        ]
    },
    // marital options index=2
    {
        maritalOptions:[
            {
                value: 'Single',
                label: 'Single',
            },
            {
                value: 'Married',
                label: 'Married',
            },
            {
                value: 'Divorced',
                label: 'Divorced',
            },
            {
                value: 'Widowed',
                label: 'Widowed',
            },
        ]
    },
    // religion options index=3
    {
        religionOptions:[
            {
                value: 'Christian',
                label: 'Christian',
            },
            {
                value: 'Islam',
                label: 'Islam',
            },
            {
                value: 'Hindu',
                label: 'Hindu',
            },
            {
                value: 'Other',
                label: 'Other',
            },
        ]
    },
    // ethnicity options index=4
    {
        ethnicityOptions:[
        {
            value: 'Basuba',
            label: 'Basuba',
        },
        {
            value: 'Boran',
            label: 'Boran',
        },
        {
            value: 'Burji',
            label: 'Burji',
        },
        {
            value: 'Dasenach',
            label: 'Dasenach',
        },
        {
            value: 'European',
            label: 'European',
        },
        {
            value: 'Embu',
            label: 'Embu',
        },
        {
            value: 'Gabbra',
            label: 'Gabbra',
        },
        {
            value: 'Galjeel',
            label: 'Galjeel',
        },
        {
            value: 'Galla',
            label: 'Galla',
        },
        {
            value: 'Gosha',
            label: 'Gosha',
        },
        {
            value: 'Ilchamus',
            label: 'Ilchamus',
        },
        {
            value: 'Isaak',
            label: 'Isaak',
        },
        {
            value: 'Kalenjin',
            label: 'Kalenjin',
        },
        {
            value: 'Kamba',
            label: 'Kamba',
        },
        {
            value: 'Kenyan American',
            label: 'Kenyan American',
        },
        {
            value: 'Kenyan Arab',
            label: 'Kenyan Arab',
        },
        {
            value: 'Kenyan Asian',
            label: 'Kenyan Asian',
        },
        {
            value: 'Kenyan European',
            label: 'Kenyan European',
        },
        {
            value: 'Kikuyu',
            label: 'Kikuyu',
        },
        {
            value: 'Kisii',
            label: 'Kisii',
        },
        {
            value: 'Kuria',
            label: 'Kuria',
        },
        {
            value: 'Leysan',
            label: 'Leysan',
        },
        {
            value: 'Luhya',
            label: 'Luhya',
        },
        {
            value: 'Luo',
            label: 'Luo',
        },
        {
            value: 'Mbeere',
            label: 'Mbeere',
        },
        {
            value: 'Meru',
            label: 'Meru',
        },
        {
            value: 'Mijikenda',
            label: 'Mijikenda',
        },
        {
            value: 'Njemps',
            label: 'Njemps',
        },
        {
            value: 'Nubi',
            label: 'Nubi',
        },
        {
            value: 'Orma',
            label: 'Orma',
        },
        {
            value: 'Rendile',
            label: 'Rendile',
        },
        {
            value: 'Sakuye',
            label: 'Sakuye',
        },
        {
            value: 'Samburu',
            label: 'Samburu',
        },
        {
            value: 'Somali',
            label: 'Somali',
        },
        {
            value: 'Swahili',
            label: 'Swahili',
        },
        {
            value: 'Taita',
            label: 'Taita',
        },
        {
            value: 'Taveta',
            label: 'Taveta',
        },
        {
            value: 'Teso',
            label: 'Teso',
        },
        {
            value: 'Tharaka',
            label: 'Tharaka',
        },
        {
            value: 'Turkana',
            label: 'Turkana',
        },
        {
            value: 'Waat',
            label: 'Waat',
        },
        {
            value: 'Walwana',
            label: 'Walwana',
        },
        {
            value: 'Other',
            label: 'Other',
        },
        ]
    },
    // disability options index=5
    {
        disabilityOptions:[
            {
                value: true,
                label: 'Yes',
            },
            {
                value: false,
                label: 'No',
            },
        ]
    },
    // homeCountry options index=6
    {
        homeCountryOptions:[
            {
                value: 'Elgeyo Marakwet',
                label: 'Elgeyo Marakwet',
            },
            {
                value: 'Nairobi City Country',
                label: 'Nairobi City Country',
            },
            {
                value: 'Mombasa',
                label: 'Mombasa',
            },
            {
                value: 'Kawale',
                label: 'Kawale',
            },
            {
                value: 'Kalifi',
                label: 'Kalifi',
            },
            {
                value: 'Tana-River',
                label: 'Tana-River',
            },
            {
                value: 'Lamu',
                label: 'Lamu',
            },
            {
                value: 'Taita-Taveta',
                label: 'Taita-Taveta',
            },
            {
                value: 'Garissa',
                label: 'Garissa',
            },
            {
                value: 'Wajir',
                label: 'Wajir',
            },
            {
                value: 'Mandera',
                label: 'Mandera',
            },
            {
                value: 'Isiolo',
                label: 'Isiolo',
            },
            {
                value: 'Meru',
                label: 'Meru',
            },
            {
                value: 'Tharaka-Nithi',
                label: 'Tharaka-Nithi',
            },
            {
                value: 'Embu',
                label: 'Embu',
            },
            {
                value: 'Kitui',
                label: 'Kitui',
            },
            {
                value: 'Machakos',
                label: 'Machakos',
            },
            {
                value: 'Makueni',
                label: 'Makueni',
            },
            {
                value: 'Nayandarua',
                label: 'Nayandarua',
            },
            {
                value: 'Nyeri',
                label: 'Nyeri',
            },
            {
                value: "Murang'a",
                label: "Murang'a",
            },
            {
                value: 'Kiambu',
                label: 'Kiambu',
            },
            {
                value: 'West-Pokot',
                label: 'West-Pokot',
            },
            {
                value: 'Trans-nzoia',
                label: 'Trans-nzoia',
            },
            {
                value: 'Uasin-Gishu',
                label: 'Uasin-Gishu',
            },
            {
                value: 'Nandi',
                label: 'Nandi',
            },
            {
                value: 'Baringo',
                label: 'Baringo',
            },
            {
                value: 'Laikipia',
                label: 'Laikipia',
            },
            {
                value: 'Nakuru',
                label: 'Nakuru',
            },
            {
                value: 'Narok',
                label: 'Narok',
            },
            {
                value: 'Kajiado',
                label: 'Kajiado',
            },
            {
                value: 'Kericho',
                label: 'Kericho',
            },
            {
                value: 'Bomet',
                label: 'Bomet',
            },
            {
                value: 'Kakamega',
                label: 'Kakamega',
            },
            {
                value: 'Vihiga',
                label: 'Vihiga',
            },
            {
                value: 'Bungoma',
                label: 'Bungoma',
            },
            {
                value: 'Busia',
                label: 'Busia',
            },
            {
                value: 'Siaya',
                label: 'Siaya',
            },
            {
                value: 'Kisumu',
                label: 'Kisumu',
            },
            {
                value: 'Kisii',
                label: 'Kisii',
            },
            {
                value: 'Nyamira',
                label: 'Nyamira',
            },
            {
                value: 'Migori',
                label: 'Migori',
            },
            {
                value: 'Homa Bay',
                label: 'Homa Bay',
            },
            {
                value: 'Marsabit',
                label: 'Marsabit',
            },
            {
                value: 'Kirinyaga',
                label: 'Kirinyaga',
            },
            {
                value: 'Turkana',
                label: 'Turkana',
            },
            {
                value: 'Samburu',
                label: 'Samburu',
            },
        ]
    },
    // homeSubCountry options index=7
    {
        homeSubCountryOptions:[
            {
                value: 'Kisumu East',
                label: 'Kisumu East',
            },
            {
                value: 'Kisumu West',
                label: 'Kisumu West',
            },
            {
                value: 'Kisumu Central',
                label: 'Kisumu Central',
            },
            {
                value: 'Seme',
                label: 'Seme',
            },
            {
                value: 'Nyando',
                label: 'Nyando',
            },
            {
                value: 'Muhoroni',
                label: 'Muhoroni',
            },
            {
                value: 'Nyakach',
                label: 'Nyakach',
            },
            {
                value: 'Other',
                label: 'Other',
            },
        ]
    },
    // homeWard options index=8
    {
        homeWardOptions:[
            {
                value: 'Githurai',
                label: 'Githurai',
            },
            {
                value: 'Kahawa West',
                label: 'Kahawa West',
            },
            {
                value: 'Zimmerman',
                label: 'Zimmerman',
            },
            {
                value: 'Roysambu',
                label: 'Roysambu',
            },
            {
                value: 'Kahawa',
                label: 'Kahawa',
            },
        ]
    },
    // residenceSubCountry options index=9
    {
        residenceSubCountryOptions:[
            {
                value: 'WestLands',
                label: 'WestLands',
            },
            {
                value: 'Dagoretti North',
                label: 'Dagoretti North',
            },
            {
                value: 'Dagoretti South',
                label: 'Dagoretti South',
            },
            {
                value: 'Langat',
                label: 'Langat',
            },
            {
                value: 'Kibra',
                label: 'Kibra',
            },
            {
                value: 'Roysambu',
                label: 'Roysambu',
            },
            {
                value: 'Kasarani',
                label: 'Kasarani',
            },
            {
                value: 'Ruaraka',
                label: 'Ruaraka',
            },
            {
                value: 'Embakasi South',
                label: 'Embakasi South',
            },
            {
                value: 'Embakasi North',
                label: 'Embakasi North',
            },
            {
                value: 'Embakasi Central',
                label: 'Embakasi Central',
            },
            {
                value: 'Embakasi East',
                label: 'Embakasi East',
            },
            {
                value: 'Embakasi West',
                label: 'Embakasi West',
            },
            {
                value: 'Makadara',
                label: 'Makadara',
            },
            {
                value: 'Kamukunji',
                label: 'Kamukunji',
            },
            {
                value: 'Starehe',
                label: 'Starehe',
            },
            {
                value: 'Mathare',
                label: 'Mathare',
            },
            {
                value: 'Ruaraka',
                label: 'Ruaraka',
            },
        ]
    },
    // --------------------Academic Details Secondary Options----------
    // grade options index=10
    {
        gradeOptions:[
            {
                value: 'A',
                label: 'A',
            },
            {
                value: 'A-',
                label: 'A-',
            },
            {
                value: 'B+',
                label: 'B+',
            },
            {
                value: 'B',
                label: 'B',
            },
            {
                value: 'B-',
                label: 'B-',
            },
            {
                value: 'C+',
                label: 'C+',
            },
            {
                value: 'C',
                label: 'C',
            },
            {
                value: 'C-',
                label: 'C-',
            },
            {
                value: 'D+',
                label: 'D+',
            },
            {
                value: 'D',
                label: 'D',
            },
            {
                value: 'D-',
                label: 'D-',
            },
            {
                value: 'E',
                label: 'E',
            },
            {
                value: 'Division I',
                label: 'Division I',
            },
            {
                value: 'Division II',
                label: 'Division II',
            },
            {
                value: 'Division III',
                label: 'Division III',
            },
            {
                value: 'Division IV',
                label: 'Division IV',
            },
        ]
    },
    // degree Status Options index=11
    {
        degreeStatusOptions:[
            {
                value: 'CERTIFICATE',
                label: 'Certificate',
            },
            {
                value: 'DIPLOMA',
                label: 'Diploma',
            },
            {
                value: 'DEGREE',
                label: 'Degree',
            },
            {
                value: 'MASTERS',
                label: 'Masters',
            },
            {
                value: 'PHD',
                label: 'PHD',
            },
            {
                value: 'OTHER',
                label: 'Other',
            },
        ]
    }
]
export default JobFormOptions;