import axios from './../libs/axios';

class SupportTicketService {

    constructor() {
        this.contentType = 'application/support.ticket.v1+json';
        this.baseUrl = "/supportTicket";
    }

    submit(payload) {
        return axios.post(this.baseUrl, payload, {
            headers: {
                "Content-Type": this.contentType
            }
        })
    }
}

export default new SupportTicketService();