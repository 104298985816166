import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import { onError } from '../../libs/errorLib';
import JobApplicationsService from '../../services/jobApplication.service';
import { DownOutlined, RightCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { Link } from 'react-router-dom';
import "./JobApplications.css";

const JobApplications = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const handleClick = (id) => {
    setSelectedId(id !== selectedId ? id : null);
  };
  // const {isLoggedIn, firstName, lastName, id} = useSelector(({auth}) => auth);

  useEffect(() => {
    getShortlists()
  }, []);
  const getShortlists = async () => {
    setIsLoading(true);
    try {
      const response = await JobApplicationsService.getList()
      // console.log("job Applications list ",response)
      setDataSource(response.results);
    } catch (e) {
      onError(e)
    } finally {
      setIsLoading(false)
    }

  }
  return (
    <div className="listing-main">
      {!isLoading ? (
        <div className="list-main-card">
          <h3 className="ribbon">My Job Applications</h3>
          {/* <p className="listing-note">Applicant must have no prior Criminal conviction or pending criminal action.</p> */}
          <div className="listing-list">
            <ol>
              {
                dataSource ?
                  dataSource?.map((job) => {
                    return (
                      <li>
                        <div className="listing-list-item">
                          {/* Vacancies- */}
                          {job?.jobTitle}
                          <DownOutlined
                            className="primary_btn"
                            onClick={() => handleClick(job?.id)}
                          />
                        </div>
                        {selectedId === job?.id && (
                          <div className="select-option">
                            {/* <button
                                                        className="primary_btn"
                                                        onClick={(e) => openAuthModal(e, job?.id)}
                                                    >
                                                        Apply
                                                    </button> */}
                            <ul id="" className="">
                              <p>
                                <b>Application Submitted :</b>{" "}
                                {moment(job?.created).format(
                                  "DD-MM-YYYY hh:mm:ss"
                                )}
                              </p>
                              <p>
                                <b>First Name :</b>{" "}
                                {job?.jobProfile?.firstName}
                              </p>
                              <p>
                                <b>Middle Name :</b>{" "}
                                {job?.jobProfile?.middleName}
                              </p>
                              <p>
                                <b>Last Name :</b>{" "}
                                {job?.jobProfile?.lastName}
                              </p>
                              <div className="collective_headings">
                                <p>
                                  {/* Application Deadline 5 Jan 2023 */}
                                  Application Deadline{" "}
                                  {moment(
                                    job?.jobOpening?.applicationDeadline
                                  ).format("DD-MM-YYYY")}
                                </p>
                                <p>
                                  No Of Positions{" "}
                                  {
                                    job?.jobOpening
                                      ?.numberOfPositions
                                  }
                                </p>
                                <p>
                                  Job Group '
                                  {
                                    job?.jobOpening?.jobPosition
                                      ?.jobGroupName
                                  }
                                  '
                                </p>
                                <p
                                  className="secondary_btn"
                                  disabled={true}
                                >
                                  Contract Type '
                                  {
                                    job?.jobOpening?.jobPosition
                                      ?.contractTypeName
                                  }
                                  '
                                </p>
                              </div>
                              <div>
                                <div className="job_details">
                                  <RightCircleOutlined />
                                  <h5>
                                    JOB GROUP "
                                    {
                                      job?.jobOpening?.jobPosition
                                        ?.jobGroupName
                                    }
                                    "
                                  </h5>
                                </div>
                                <div className="job_details">
                                  <RightCircleOutlined />
                                  <h5>
                                    No Of Positions{" "}
                                    {
                                      job?.jobOpening?.numberOfPositions
                                    }
                                  </h5>
                                </div>
                                <div className="job_details">
                                  <RightCircleOutlined />
                                  <h5>
                                    Salary Range: KES ({" "}
                                    {
                                      job?.jobOpening?.jobPosition
                                        ?.minSalary
                                    }
                                    -{" "}
                                    {
                                      job?.jobOpening?.jobPosition?.maxSalary
                                    }
                                    )
                                  </h5>
                                </div>
                                <div className="job_details">
                                  <RightCircleOutlined />
                                  <h5>
                                    House Allowance: KES{" "}
                                    {
                                      job?.jobOpening?.jobPosition?.houseAllowance
                                    }
                                  </h5>
                                </div>
                                <div className="job_details">
                                  <RightCircleOutlined />
                                  <h5>
                                    Commuter Allowance: KES{" "}
                                    {
                                      job?.jobOpening?.jobPosition?.commuterAllowance
                                    }
                                  </h5>
                                </div>
                                <div className="job_details">
                                  <RightCircleOutlined />
                                  <h5>
                                    Location:{" "}
                                    {job?.jobOpening?.location}
                                  </h5>
                                </div>
                              </div>
                              <div className="job_requirement_detail">
                                <h4>Job Requirement</h4>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      job?.jobPosition?.qualification,
                                  }}
                                />
                              </div>

                              <div className="job_requirement_detail">
                                <h4>Job Description</h4>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      job?.jobPosition?.description,
                                  }}
                                />
                              </div>
                            </ul>
                            {/* <button
                                                        className="primary_btn"
                                                        onClick={(e) => openAuthModal(e, job?.id)}
                                                    >
                                                        Apply
                                                    </button> */}
                          </div>
                        )}
                      </li>
                    );
                  })
                  : <p>No vacancies</p>
              }
            </ol>
          </div>

          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Link to="/vacancies" className="primary_btn">Apply More</Link>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  )
}

export default JobApplications