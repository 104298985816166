import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { HeadCarouselData } from './HeadCarouselData';
import "./HeadCarousel.css" ;

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

const HeadCarousel = () => {
  return (
    <div className='head-banner-main'>
        <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            autoPlay={true}
            // ssr={false} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            // autoPlaySpeed={1000}
            // keyBoardControl={true}
            // customTransition="all .5"
            // transitionDuration={4000}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            // deviceType={this.props.deviceType}
            // dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            >   
                {HeadCarouselData?.map((banner)=>{
                    return(
                        <div className='head-banner'>
                            {/* <div className='banner-caption'>
                              <p>
                                {banner.caption}
                              </p>  
                            </div> */}
                            <img className="banner-image" src={banner.image} alt="head banner"/>
                        </div>
                    )
                })}
        </Carousel>
    </div>
  )
}

export default HeadCarousel