import React, {useState} from 'react';
import DocumentService from "../../../services/document.service";
import {FaDownload} from "react-icons/fa";
import {AiOutlineEye} from "react-icons/ai"
import {BiHide} from "react-icons/bi";


const DocumentList = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [iframe, setIframe] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [showIframe, setShowIframe] = useState(false);
    const [blob, setBlob] = useState(null)
    const [fileName, setFileName] = useState(null)
    const {title} = props.doc

    const view = async () => {
        const {type} = props
        if (!iframe) {
            setIsLoading(true)
            const {docId, filename} = props.download
            if (type === 'notice') {
                var response = await DocumentService.getNotices(docId)
            } else {
                var response = await DocumentService.getShortlists(docId)

            }
            const file = new File([response], filename);
            setIframe(URL.createObjectURL(response))
            setLoaded(true)
            setIsLoading(false)
            setBlob(file)
            setShowIframe(true)
            setFileName(filename)
        } else {
            setLoaded(true)
            setShowIframe(true)
        }

    }
    const download = async () => {
        const {type} = props
        if (!iframe) {
            setIsDownloading(true)
            const {docId, filename} = props.download
            if (type === 'notice') {
                var response = await DocumentService.getNotices(docId)
            } else {
                var response = await DocumentService.getShortlists(docId)
            }
            const file = new File([response], filename);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = filename;
            link.click();
            setIframe(link.href)
            setLoaded(true)
            setIsDownloading(false)
            setBlob(file)
            setFileName(filename)
        } else {
            setLoaded(true)
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        }
    }
    return (
        <>
            <li>
                <div className='listing-list-item'>
                    {title}
                    <div className='listing-list-buttons'>
                        {loaded ?
                            <>
                                {showIframe ?
                                    <button onClick={() => setShowIframe(false)} className='primary_btn'><BiHide/> Hide
                                    </button> :
                                    <button onClick={view} className='primary_btn'
                                            disabled={isLoading}>  {isLoading ? <>Loading...</> : <>
                                        <AiOutlineEye/> view</>}
                                    </button>
                                }

                                <button onClick={download} className='primary_btn'><FaDownload/> Download</button>
                            </>
                            : <>
                                <button onClick={view} className='primary_btn'
                                        disabled={isLoading}> {isLoading ? <>Loading... </> : <>
                                    <AiOutlineEye/> view</>}
                                </button>
                                <button onClick={download} disabled={isDownloading}
                                        className='primary_btn'>
                                    <FaDownload/> {isDownloading ? 'Downloading...' : 'Download '}</button>

                            </>
                        }
                    </div>

                </div>
                <div>
                    {(showIframe) && <iframe width="100%" height="500px" src={iframe}/>}
                </div>
            </li>

        </>
    )
}

export default DocumentList