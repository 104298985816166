import React,{useEffect, useState} from 'react';
import "./ListingPages.css";
import ShortListsService from '../../services/shortlists.service';
import { onError } from '../../libs/errorLib';
import DocumentList from "./Document/DocumentList";
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';


const ShortLists = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        getShortlists()
    }, []);
    const getShortlists = async () => {
        setIsLoading(true);
        try {
            const response = await ShortListsService.getList()
            setDataSource(response.results);
        } catch (e) {
            onError(e)
        } finally {
            setIsLoading(false)
        }

    }
  return (
      <>
          <div className="listing-main">
              <div className="list-main-card">
                  <h3 className="ribbon">Shortlist(s)</h3>
                    {
                      isLoading ? <LoadingSpinner/> :
                          <div className="listing-list">
                              <ol>
                                  {
                                      dataSource ?
                                          dataSource.map(data => {
                                              return (
                                                  <DocumentList type="shortlist" doc={data} download={data.shortlistDownload}/>
                                              )
                                          })
                                          : 'No Data'
                                  }

                              </ol>

                          </div>
                    }

              </div>
          </div>
      </>
  )
}

export default ShortLists