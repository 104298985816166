import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./Auth/authSlice";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import { combineReducers } from "@reduxjs/toolkit";

const persistConfiguration = {
  key: "persist-key",
  storage,
};

const reducer = combineReducers({
  auth: authReducer,
});

const persistedReducer = persistReducer(persistConfiguration, reducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export default store;