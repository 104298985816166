import React, {useState, useEffect} from "react";
import {Modal, Form, Input, Button} from "antd";
import {onError} from "../../libs/errorLib";
import EditProfileService from "../../services/editProfile.service"
import {onSucess} from "../../libs/onSuccess";
import {setLoginUser, setUser} from "../../Store/Auth/authSlice";
import {useDispatch, useSelector} from "react-redux";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const EditProfile = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const authUser=useSelector(({auth}) => auth);

    useEffect(() => {
        if(authUser.isLoggedIn)
        getProfile();
    }, [authUser]);


    const onSubmit = async (values) => {
        setLoading(true)
        try {
            const profile = {
            "firstName": values.firstName,
            "middleName": values.middleName,
            "lastName": values.lastName,
            "mobileNumber":values.mobileNumber,
            "email":values.email,
            "nationalId":values.nationalId,
            }
            const response = await EditProfileService.update(profile)
            onSucess("Update successfully.")
            await getProfile();
            dispatch(setLoginUser(values));
        } catch (e) {
            onError(e)
        } finally {
            props.handleCancel()
            setLoading(false)
        }
    };
    const getProfile = async () => {
        try {
            const result = await EditProfileService.getUserProfile()
            // console.log( "get user profile",result)
            form.setFieldsValue(result);
            
            dispatch(setUser(result))
        } catch (e) {
            onError(e)
        }
    }
    return (
      <>
        <Modal
          className="modal"
          open={props.open}
          title={<div className="modal-head">Edit Profile</div>}
          // onOk={handleOk}
          onCancel={props.handleCancel}
          footer={<></>}
        >
          <Form form={form} onFinish={onSubmit}>
            <div className="merge-fields">
              <Form.Item
                className="input-50"
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="text" placeholder="First Name" />
              </Form.Item>
              <Form.Item
                className="input-50"
                label="Middle Name"
                name="middleName"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="text" placeholder="Middle Name" />
              </Form.Item>
            </div>
            <div className="merge-fields">
              <Form.Item
                className="input-50"
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="text" placeholder="Last Name" />
              </Form.Item>
              <Form.Item
                className="input-50"
                label="Mobile Number"
                name="mobileNumber"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  type="number"
                  title="not editable"
                  placeholder="Mobile Number"
                />
              </Form.Item>
            </div>
            <div className="merge-fields">
              <Form.Item
                className="input-50"
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input type="email" title="not editable" placeholder="Email" />
              </Form.Item>
              <Form.Item
                className="input-50"
                label="National Id"
                name="nationalId"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  type="number"
                  title="not editable"
                  placeholder="National Id"
                />
              </Form.Item>
            </div>
            {/* <div className="merge-fields">
                            <Button loading={loading} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </div> */}
            <div className="modal-footer">
              <button
                className="secondary_btn"
                // key="back"
                onClick={props.handleCancel}
              >
                Cancel
              </button>
              <button
                loading={loading}
                className="primary_btn"
                htmlType="submit"
                // key="back"
                // onClick={cancelRegistrationForm}
              >
                {loading ? <LoadingSpinner /> : "Edit Profile"}
              </button>
            </div>
          </Form>
        </Modal>
      </>
    );
};

export default EditProfile;
