import axios from './../libs/axios';

class AuthService {
    constructor() {
        this.contentType='application/x-www-form-urlencoded'
    }

    login(payload) {
        return axios.post(`oauth/token`, payload,{
            headers:{
                "Content-Type":this.contentType
            }
        });
    }
    getAccessToken(payload){
        return axios(this.contentType).post(`oauth/token`, payload);
    }
    register(payload){
        const contentType='application/user.create.v1+json'
        return axios.post(`users/register`, payload,{
            headers:{
                "Content-Type":contentType
            }
        });

    }
    profile(){
        return axios.get(`users/profile`, {});
    }
}

export default new AuthService();