import {UploadOutlined} from '@ant-design/icons';
import {Button, Upload} from 'antd';
import {useEffect, useState} from 'react';
import {onError} from "../../libs/errorLib";
import DocumentService from "../../services/document.service"
import {onSucess} from "../../libs/onSuccess";

const SingleFileUpload = (props) => {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [fileId, setFileId] = useState(props.fileId)
    const [blob, setBlob] = useState(null)
    const [downloading, setIsDownloading] = useState(false)
    const [iframe, setIframe] = useState(false)
    const [fileName, setFileName] = useState(props.fileName)
    const [uploaded,setUploaded]=useState(true)
    useEffect(() => {
        if (fileId) {
            if (fileList.length === 0) {
                setFileList([...fileList, {
                    uid: '-1',
                    name: fileName,
                }]);
            }
        }
        props.setFileId(fileId)
    }, [fileId])
    const handleUpload = async () => {
        setUploading(true);
        let fileId;
        try {
            const data = {
                "file": fileList[0],

            }
            if(props.type=="general"){
                // console.log("upload type", props.type)
                fileId = await DocumentService.singleFileUpload(data)
            } else if(props.type=="news"){
                // console.log("upload type", props.type)
                fileId = await DocumentService.newsUpload(data)
            } else if(props.type=="notices"){
                // console.log("upload type", props.type)
                fileId = await DocumentService.noticesUpload(data)
            } else if(props.type=="shortlists"){
                // console.log("upload type", props.type)
                fileId = await DocumentService.shortlistsUpload(data)
            }
            setFileId(fileId)
            setUploaded(true)
            onSucess("Upload Successfully")
        } catch (e) {
            onError(e)
        } finally {
            setUploading(false);

        }
    };
    const fileObject = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setFileId(null)
            setFileName(null)
            setIframe(null)
        },
        beforeUpload: (file) => {
            setFileName(file.name)
            setUploaded(false)
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    const handleDownload = async () => {
        if (!iframe) {
            setIsDownloading(true)
            let response;
            if(props.type=="general"){
                // console.log("upload type", props.type)
                response = await DocumentService.get(fileId)
            } else if(props.type=="news"){
                // console.log("upload type", props.type)
                response = await DocumentService.getNews(fileId)
            } else if(props.type=="notices"){
                // console.log("upload type", props.type)
                response = await DocumentService.getNotices(fileId)
            } else if(props.type=="shortlists"){
                // console.log("upload type", props.type)
                response = await DocumentService.getShortlists(fileId)
            }
            const file = new File([response], 'download');
            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = fileName;
            link.click();
            setIframe(link.href)
            setIsDownloading(false)
            setBlob(file)
        } else {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        }
    }


    return (
        <>
            <Upload {...fileObject}
             accept={props.acceptType}>
                {
                    fileList.length === 0 &&
                    <button className='secondary_btn'><UploadOutlined/> Select File</button>
                }
            </Upload>


            <button
                className='primary_btn'
                type="button"
                onClick={handleUpload}
                disabled={uploaded}
                loading={uploading}
                style={{
                    marginTop: 16,
                    marginRight:10
                }}
            >
                {uploading ? 'Uploading...' : 'Start Upload'}
            </button>
            {
                fileId && <button type="button"
                    className='primary_btn'
                    onClick={handleDownload}
                    loading={downloading}
                    style={{
                        marginTop: 16,
                    }}
                >
                    {downloading ? 'Downloading...' : 'Download'}
                </button>
            }

        </>
    );
};
export default SingleFileUpload;



// -----------------Awais bhai old code
// import {UploadOutlined} from '@ant-design/icons';
// import {Button, Upload} from 'antd';
// import {useEffect, useState} from 'react';
// import {onError} from "../../libs/errorLib";
// import DocumentService from "../../services/document.service"
// import {onSucess} from "../../libs/onSuccess";

// const SingleFileUpload = (props) => {
//     const [fileList, setFileList] = useState([]);
//     const [uploading, setUploading] = useState(false);
//     const [fileId, setFileId] = useState(props.fileId)
//     const [blob, setBlob] = useState(null)
//     const [downloading, setIsDownloading] = useState(false)
//     const [iframe, setIframe] = useState(false)
//     const [fileName, setFileName] = useState(props.fileName)
//     const [uploaded,setUploaded]=useState(true)
//     useEffect(() => {
//         if (fileId) {
//             if (fileList.length === 0) {
//                 setFileList([...fileList, {
//                     uid: '-1',
//                     name: fileName,
//                 }]);
//             }
//         }
//         props.setFileId(fileId)
//     }, [fileId])
//     const handleUpload = async () => {
//         setUploading(true);
//         try {
//             const data = {
//                 "file": fileList[0],

//             }
//                const fileId = await DocumentService.singleFileUpload(data)
//             setFileId(fileId)
//             setUploaded(true)
//             onSucess("Upload Successfully")
//         } catch (e) {
//             onError(e)
//         } finally {
//             setUploading(false);

//         }
//     };
//     const fileObject = {
//         onRemove: (file) => {
//             const index = fileList.indexOf(file);
//             const newFileList = fileList.slice();
//             newFileList.splice(index, 1);
//             setFileList(newFileList);
//             setFileId(null)
//             setFileName(null)
//             setIframe(null)
//         },
//         beforeUpload: (file) => {
//             setFileName(file.name)
//             setUploaded(false)
//             setFileList([...fileList, file]);
//             return false;
//         },
//         fileList,
//     };
//     const handleDownload = async () => {
//         if (!iframe) {
//             setIsDownloading(true)
//             const response = await DocumentService.get(fileId)
//             const file = new File([response], 'download');
//             const link = document.createElement('a');
//             link.href = URL.createObjectURL(file);
//             link.download = fileName;
//             link.click();
//             setIframe(link.href)
//             setIsDownloading(false)
//             setBlob(file)
//         } else {
//             const link = document.createElement('a');
//             link.href = URL.createObjectURL(blob);
//             link.download = fileName;
//             link.click();
//         }
//     }
//     return (
//         <>

//             <Upload {...fileObject}>
//                 {
//                     fileList.length === 0 &&
//                     <Button icon={<UploadOutlined/>}>Select File</Button>
//                 }
//             </Upload>


//             <Button
//                 type="primary"
//                 onClick={handleUpload}
//                 disabled={uploaded}
//                 loading={uploading}
//                 style={{
//                     marginTop: 16,
//                 }}
//             >
//                 {uploading ? 'Uploading' : 'Start Upload'}
//             </Button>
//             {
//                 fileId && <Button
//                     type="primary"
//                     onClick={handleDownload}
//                     loading={downloading}
//                     style={{
//                         marginTop: 16,
//                     }}
//                 >
//                     {downloading ? 'Downloading' : 'Download'}
//                 </Button>
//             }

//         </>
//     );
// };
// export default SingleFileUpload;