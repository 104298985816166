import React, {useState, useEffect} from 'react';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import {onError} from '../../libs/errorLib';
import NoticesService from '../../services/notices.service';
import DocumentList from "./Document/DocumentList";

const Notices = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        getNotices()
    }, []);
    const getNotices = async () => {
        setIsLoading(true);
        try {
            const response = await NoticesService.getList()
            setDataSource(response.results);
        } catch (e) {
            onError(e)
        } finally {
            setIsLoading(false)
        }

    }
    return (
        <>
            <div className="listing-main">
                <div className="list-main-card">
                    <h3 className="ribbon">Notice(s)</h3>
                    {
                        isLoading ? <LoadingSpinner/>    :
                            <div className="listing-list">
                                <ol>
                                    {
                                        dataSource ?
                                            dataSource.map(data => {
                                                return (
                                                    <DocumentList type="notice" doc={data} download={data.noticeDownload}/>
                                                )
                                            })
                                            : 'No Data'
                                    }

                                </ol>

                            </div>
                    }

                </div>
            </div>
        </>
    )
}

export default Notices