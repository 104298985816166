import React, { useEffect, useState } from 'react';
import moment from 'moment';
import "./HomeSectionCard.css";
import DocumentService from '../../services/document.service';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { Link } from 'react-router-dom';
import parse from "html-react-parser";
import { Image } from 'antd';

const HomeUpdateCard = ({
  type,
  id,
  status,
  content,
  imageID,
  created,
  title,
}) => {
  const [imageSrc, setImageSrc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (imageID) getimage(imageID);
  }, [imageID]);

  const getimage = async () => {
    setIsLoading(true);
    let response;
    // const filename=""
    if (type === "news") {
      response = await DocumentService.getNews(imageID);
    } else if (type === "notices") {
      response = await DocumentService.getNotices(imageID);
    } else if (type === "shortlists") {
      response = await DocumentService.getShortlists(imageID);
    }
    setIsLoading(false);
    setImageSrc(URL.createObjectURL(response));
  };
  // {console.log("image id",imageSrc)}

  return (
    <div className="home_update_card" key={id}>
      <Link to={`type/${type}/${id}`}>
        {isLoading ? (
          <LoadingSpinner />
        ) : imageSrc ? (
          <div className="home_update_image">
            {/* <iframe width="100%" height="100%" src={imageSrc} /> */}
            <Image width="100%" alt='image' height={200} src={imageSrc} visible="false" />
            {/* <img src={imageSrc} alt="image" /> */}
          </div>
        ) : (
          <>No Image</>
        )}
        <div className="home_update_details">
          {created && <h5>{moment(created).format("YYYY-MM-DD")} </h5>}
          <hr />
          <h4 title={title}>
            {/* title.length > 20 ?
                    <>
                        {title.substring(0, 20) + "..."} */}
            {/* </> :  */}
            {title}
            {/* } */}
          </h4>
          {content ? (
            <p title={content}>
              {content?.length > 50
                ? parse(content.substring(0, 50) + "...")
                : parse(`${content}`)}
            </p>
          ) : (
            <p></p>
          )}
          {/*<p>{status}</p>*/}

          <div className="update_read_more">
            <Link to={`type/${type}/${id}`} className="secondary_btn">
              Read More
            </Link>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default HomeUpdateCard