import React, {useState, useEffect} from "react";
import Topbar from "./Topbar";
import NavLogo from "../../assets/images/fullLogo.png";
import "./Navbar.css";
import {MenuData} from "./MenuData";
import {Link, useNavigate} from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import AuthModal from "./AuthModal";
import MediaQuery from "react-responsive";
import {Button, Drawer, Dropdown, Radio, Space} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {removeLoginUser} from "../../Store/Auth/authSlice";
import EditProfileModal from "./EditProfile";
import ResetPasswordModal from "./ChangePasswordModal";
import {deleteCookie} from "../../libs/cookie";
import {onInfo} from "../../libs/onInfo";


const Navbar = () => {

    const dispatch = useDispatch();
    const [stickyClass, setStickyClass] = useState("");
    const {isLoggedIn, firstName, lastName} = useSelector(({auth}) => auth);

    useEffect(() => {
        window.addEventListener("scroll", stickNavbar);
        return () => window.removeEventListener("scroll", stickNavbar);
    }, []);

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            // window height changed for the demo
            windowHeight > 30 ? setStickyClass("sticky-nav") : setStickyClass("");
        }
    };

    //  ---------Auth Modal-----
    const [authModalOpen, setAuthModalOpen] = useState(false);

    const openAuthModal = () => {
        setDrawerMenuOpen(false);
        setAuthModalOpen(true);
    };
    const closeAuthModal = () => {
        setAuthModalOpen(false);
    };

    //   ------Drawer menu-------
    const [drawerMenuOpen, setDrawerMenuOpen] = useState(false);
    const openDrawerMenu = () => {
        setDrawerMenuOpen(true);
    };
    const closeDrawerMenu = () => {
        setDrawerMenuOpen(false);
    };

    // --------Drawer Sub-menu--------
    const [drawerSubmenuOpen, setDrawerSubmenuOpen] = useState(false);
    const [submenuIcon, setSubmenuIcon] = useState("");
    const [selectedId, setSelectedId] = useState();
    const navigate = useNavigate();

    const handleClick = (id) => {
        setSelectedId(id !== selectedId ? id : null);
    };
    const showSubmenu = () => {
        setDrawerSubmenuOpen((drawerSubmenuOpen) => !drawerSubmenuOpen);
        drawerSubmenuOpen
            ? setSubmenuIcon("submenu-icondown")
            : setSubmenuIcon("submenu-iconup");
    };


    // ---------------Logged in user Dropdown------
    const onClick = ({key}) => {
        // console.log(key)
        if (key == 1) {
            // Manage My Profile
            navigate("job-profile")
        }else if (key == 2) {
            // My Job Application
            navigate("/my-job-applications")
        } else if (key == 3) {
            // Edit Profile
            openEditModal()
        } else if (key == 4) {
            // Reset Password
            openResetModal()
        } else if (key == 5) {
            // LogOut option
            logout();
        }
    }
    const items = [
        {
            label: 'Manage Job Profile',
            key: '1',
        },
        {
            label: 'My Job Applications',
            key: '2',
        },
        {
            label: 'Edit Profile',
            key: '3',
        },
        {
            label: 'Change Password',
            key: '4',
        },
        {
            label: 'Log Out',
            key: '5',
        },
    ]
// ------------Log Out-------------
    const logout = () => {
        dispatch(removeLoginUser())
        deleteCookie("access_token")
        deleteCookie("refresh_token")
        onInfo("Logout Successfully")
        navigate("/")
    }
// ------------Edit Profile Modal----------
    const [editModalOpen, setEditModalOpen] = useState(false);
    const openEditModal = () => {
        setDrawerMenuOpen(false);
        setEditModalOpen(true);
    };
    const closeEditModal = () => {
        setEditModalOpen(false);
    };
// ------------Reset Password Modal----------
    const [resetModalOpen, setResetModalOpen] = useState(false);
    const openResetModal = () => {
        setDrawerMenuOpen(false);
        setResetModalOpen(true);
    };
    const closeResetModal = () => {
        setResetModalOpen(false);
    };

    return (
        <>
            <Topbar/>
            <div className={`navbar ${stickyClass}`}>
                <div className="Nav-Logo">
                    <Link to="/">
                        <img src={NavLogo} alt="Logo"/>
                    </Link>
                </div>

                <MediaQuery minWidth={851}>
                    <div className="bar">
                        <nav>
                            {MenuData?.map((item) => {
                                if (item.subMenu && item.subMenu.length !== 0) {
                                    return (
                                        <>
                                            <Link to={item.path} key={item.key} className="main-menu">
                                                {item.title} <AiIcons.AiOutlineDown/>
                                                <div className="drop-down">
                                                    {item.subMenu?.map((submenu) => {
                                                        return (
                                                            <>
                                                                <Link to={submenu.path} key={submenu.key}>
                                                                    {submenu.title}
                                                                </Link>
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                            </Link>
                                        </>
                                    );
                                } else {
                                    return (
                                        <>
                                            {
                                                item.path === 'job-profile' ? (isLoggedIn ?
                                                        <Link to={item.path} key={item.key} className="main-menu">
                                                            {item.title}
                                                        </Link> : <Link onClick={openAuthModal} key={item.key}
                                                                        className="main-menu">
                                                            {item.title}
                                                        </Link>
                                                    )
                                                    :
                                                    <Link to={item.path} key={item.key} className="main-menu">
                                                        {item.title}
                                                    </Link>
                                            }

                                        </>
                                    );
                                }
                            })}
                            {
                                isLoggedIn ?
                                    <Dropdown
                                        menu={{
                                            items,
                                            onClick,
                                        }}
                                    >
                                        <button className="primary_btn"
                                        >
                                            Hi, {firstName} {lastName}
                                        </button>
                                    </Dropdown>
                                    : <button className="primary_btn" onClick={openAuthModal}>
                                        Register/Login
                                    </button>
                            }

                        </nav>
                    </div>
                </MediaQuery>

                <MediaQuery maxWidth={851}>
                    <AiIcons.AiOutlineMenuFold
                        onClick={openDrawerMenu}
                        className="menu-drawer-button"
                    />
                </MediaQuery>

                <Drawer
                    width={325}
                    className="menu-drawer"
                    title={
                        <div className="menu-drawer-Logo">
                            <Link to="/" onClick={closeDrawerMenu}>
                                <img src={NavLogo} alt="Logo"/>
                            </Link>
                        </div>
                    }
                    placement="right"
                    closable={true}
                    onClose={closeDrawerMenu}
                    open={drawerMenuOpen}
                >
                    {MenuData?.map((item) => {
                        if (item.subMenu && item.subMenu.length !== 0) {
                            return (
                                <div key={item.key} className="drawer-menu-item">
                                    <h3 className="drawer-main-menu">
                                        <Link to={item.path} onClick={closeDrawerMenu}>
                                            {item.title}
                                        </Link>{" "}
                                        <AiIcons.AiOutlineDown
                                            className={`${submenuIcon}`}
                                            // onClick={showSubmenu}
                                            onClick={() => handleClick(item.key)}
                                        />
                                    </h3>
                                    {selectedId === item.key && (
                                        <div className="drawer-sub-menu">
                                            {item.subMenu?.map((submenu) => {
                                                return (
                                                    <h4 key={submenu.key}>
                                                        <Link to={submenu.path} onClick={closeDrawerMenu}>
                                                            {submenu.title}
                                                        </Link>
                                                    </h4>
                                                );
                                            })}
                                        </div>
                                    )}
                                    {/* {drawerSubmenuOpen ? (
                                        <div className="drawer-sub-menu">
                                            {item.subMenu?.map((submenu) => {
                                                return (
                                                    <h4 key={submenu.key}>
                                                        <Link to={submenu.path} onClick={closeDrawerMenu}>
                                                            {submenu.title}
                                                        </Link>
                                                    </h4>
                                                );
                                            })}
                                        </div>
                                    ) 
                                    : (
                                        <></>
                                    )} */}

                                </div>
                            );
                        } else {
                            return (
                                <>
                                    <h3
                                        key={item.key}
                                        className="drawer-main-menu drawer-menu-item"
                                    >
                                        <Link to={item.path} onClick={closeDrawerMenu}>
                                            {item.title}
                                        </Link>
                                    </h3>
                                </>
                            );
                        }
                    })}
                    {isLoggedIn ?
                        <Dropdown
                            placement="bottom"
                            menu={{
                                items,
                                onClick,
                            }}
                        >
                            <h3
                                className="drawer-main-menu drawer-menu-item"
                            >
                                {/* <a onClick={(e) => e.preventDefault()}> */}
                                Hi, {firstName} {lastName}
                                {/* </a> */}
                            </h3>
                        </Dropdown> : <h3
                            className="drawer-main-menu drawer-menu-item"
                            onClick={openAuthModal}
                        >
                            Register/Login
                        </h3>
                    }

                </Drawer>

                <ResetPasswordModal handleCancel={closeResetModal} open={resetModalOpen}/>
                <EditProfileModal handleCancel={closeEditModal} open={editModalOpen}/>
                <AuthModal handleCancel={closeAuthModal} open={authModalOpen}/>
            </div>
        </>
    )
}

export default Navbar;
