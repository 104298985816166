import axios from './../libs/axios';

class EditProfileService {
    constructor() {
        this.contentType='application/user.profile.v1+json'
    }
    update(payload){
        return axios.put(`/users/profile`, payload, {
            headers: {
                'Content-Type':this.contentType
            }
        });
    }
    getUserProfile(){
        return axios.get(`/users/profile`, {});
    }
}

export default new EditProfileService();