import React, {useEffect, useState} from 'react';
import {Checkbox, DatePicker, Form, Input, Modal} from 'antd';
import dayjs from "dayjs";

const AddEmploymentModal = (props) => {
    const [form] = Form.useForm()
    const [id, setId] = useState(null)
    useEffect(() => {
      const history = props.history;
      // form.setFieldsValue(history)
      if (history) {
        setId(props.history.id);
        var his = JSON.parse(JSON.stringify(history));
        var periodFrom = new Date(props.history.periodFrom);
        periodFrom = dayjs(periodFrom);
        var periodTo = new Date(props.history.periodTo);
        periodTo = dayjs(periodTo);
        his.periodFrom = periodFrom;
        his.periodTo = periodTo;
        form.setFieldsValue(his);
      } else {
        setId(null);
      }
    }, [props.history]);
    const onSubmit = (values) => {
      values.periodFrom = dayjs(values.periodFrom).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      values.periodTo = dayjs(values.periodTo).format("YYYY-MM-DDTHH:mm:ss");
      props.addHistory(values, id);
    };
    return (
      <Modal
        className="modal"
        open={props.open}
        title={<div className="modal-head">Employment History</div>}
        // onOk={handleOk}
        onCancel={props.handleCancel}
        footer={false}
        // footer={
        //     <div className='modal-footer'>
        //         <button className='secondary_btn' key="back" onClick={props.handleCancel}>
        //             Close
        //         </button>
        //     </div>
        // }
      >
        <Form onFinish={onSubmit} form={form}>
          <div className="merge-fields">
            <Form.Item
              className="input-50"
              label="Company/Employer Name"
              name="employerName"
              rules={[{ required: true }]}
            >
              <Input type="text" placeholder="Company/Employer Name" />
            </Form.Item>
            <Form.Item
              className="input-50"
              label="Job Title"
              name="jobTitle"
              rules={[{ required: true }]}
            >
              <Input type="text" placeholder="Job Title" />
            </Form.Item>
          </div>

          <Form.Item
            label="Job Description"
            name="jobDescription"
            rules={[{ required: true }]}
          >
            <Input.TextArea type="text" placeholder="Job Description" />
          </Form.Item>

          <Form.Item name="currentJob">
            <Checkbox
              value="Current Job"
              style={{
                lineHeight: "32px",
              }}
            >
              Current Job
            </Checkbox>
          </Form.Item>

          <div className="merge-fields">
            <Form.Item
              className="input-50"
              label="Period from"
              name="periodFrom"
              rules={[{ required: true }]}
            >
              <DatePicker picker="date" placeholder="From" />
            </Form.Item>
            <Form.Item
              className="input-50"
              label="Period to"
              name="periodTo"
              rules={[{ required: true }]}
            >
              <DatePicker picker="date" placeholder="to" />
            </Form.Item>
          </div>
          <button type="submit" className="primary_btn" key="back">
            Save
          </button>
        </Form>
      </Modal>
    );
}

export default AddEmploymentModal