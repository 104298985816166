import React from 'react';
import * as BsIcons from "react-icons/bs";
import * as FaIcons from "react-icons/fa";

const Topbar = () => {
  return (
    <div className="topbar">
        <div className='topbar-left'>
            <div className="topbar-contact">
                <div className='topbar-icon'>
                    <BsIcons.BsFillHouseFill/>
                </div>
                <span>City Hall, P.O. Box 30075-00100, Nairobi, Kenya</span>
            </div>
            <div className="topbar-contact">
                <div className='topbar-icon'>
                    <BsIcons.BsTelephoneFill/>
                </div>
                <span>020 2177325</span>
            </div>
            <div className="topbar-contact">
                <div className='topbar-icon'>
                    <BsIcons.BsEnvelopeFill/>
                </div>
                <span>cpsb@nairobi.go.ke</span>
            </div>
        </div>
        {/* <div className='topbar-right'>
            <div className='topbar-right-icon'>
                <a href="https://www.facebook.com/CountyGovernment047/" target="_blank">
                    <FaIcons.FaFacebookF/>
                </a>
            </div>
            <div className='topbar-right-icon'>
                <a href="https://twitter.com/047County" target="_blank">
                    <FaIcons.FaTwitter/>
                </a>
            </div>
        </div> */}
    </div>
  )
}

export default Topbar